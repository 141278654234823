import { Condition } from "../../../Elements";
import { Reference } from "../../../Reference/Reference";

export enum HOAFinancialsSection {
  PERCENT_FUNDED = "Percent Funded",
  AGNSY_RATING = "Agnsy Rating",
}

export interface HOAFinancialsAnalysis {
  percent_funded: string;
  rating: PercentFundedRating;
  reference: Reference | null;
}

export enum PercentFundedRating {
  CAUTION = "CAUTION",
  POOR = "POOR",
  FAIR = "FAIR",
  ADEQUATE = "ADEQUATE",
  GOOD = "GOOD",
  EXCELLENT = "EXCELLENT",
  UNDETERMINABLE = "UNDETERMINABLE",
}
export function isRatingValue(conditionString: string): boolean {
  return Object.values(Condition).includes(conditionString as Condition);
}
