import NavbarUnauthorized from "../../Common/Navbar/NavbarUnauthorized";

import "./VerifyEmailNote.css";

function VerifyEmailNote() {
  return (
    <div>
      <NavbarUnauthorized />
      <div className="verify-email-note">
        <p>Thank you for providing your email!</p>
        <p>
          Please check your inbox for a verification email, and follow the
          included steps.
        </p>
      </div>
    </div>
  );
}

export default VerifyEmailNote;
