// Import Firestore instance and necessary functions
import { db } from "./firestore";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

import Stripe from "stripe";
import { getCurrentUser } from "../authentication/auth";

const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY ?? "");

// Function to add a user to the Firestore database
const addUserFirestore = async (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string
) => {
  const usersRef = collection(db, "users"); // Reference to the 'users' collection
  addDoc(usersRef, {
    firstName,
    lastName,
    phoneNumber,
    email,
  }) // Add a new document
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);
    })
    .catch((e) => {
      console.error("Error adding document: ", e);
    });
};
// Function to delete a user from the Firestore database based on email
const removeUserFirebase = async (email: string) => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));

  getDocs(q)
    .then((querySnapshot) => {
      let deletedCount = 0;
      const docs = querySnapshot.docs;
      const deletePromises = docs.map((doc) => {
        const subscriptionID = doc.data().subscription_id;
        if (subscriptionID) {
          // Cancel the subscription via Stripe and then delete the user
          return stripe.subscriptions
            .cancel(subscriptionID)
            .then(() => {
              console.log(
                `Subscription ${subscriptionID} canceled for ${email}`
              );
            })
            .then(() => {
              deleteDoc(doc.ref);
              deletedCount++;
            });
        } else {
          // Directly delete the user if there's no subscription
          return deleteDoc(doc.ref).then(() => {
            deletedCount++;
          });
        }
      });

      return Promise.all(deletePromises).then(() => deletedCount);
    })
    .then((deletedCount) => {
      if (deletedCount > 0) {
        console.log(`${deletedCount} document(s) deleted with email: ${email}`);
      } else {
        console.log(`No documents found with email: ${email}`);
      }
    })
    .catch((e) => {
      console.error("Error removing user or canceling subscription: ", e);
    });
};

// Function to update a user's subscription ID based on their email
const updateUserSubscription = async (
  email: string,
  subscriptionId: string,
  customerID: string
) => {
  const usersRef = collection(db, "users");
  const user = query(usersRef, where("email", "==", email));

  getDocs(user)
    .then((querySnapshot) => {
      let updatedCount = 0;
      const docs = querySnapshot.docs;
      const updatePromises = docs.map((doc) => {
        return updateDoc(doc.ref, {
          subscription_id: subscriptionId,
          customer_id: customerID,
        }).then(() => {
          updatedCount++;
        });
      });

      return Promise.all(updatePromises).then(() => updatedCount);
    })
    .then((updatedCount) => {
      if (updatedCount > 0) {
        console.log(
          `${updatedCount} document(s) updated with new subscription ID: ${subscriptionId}`
        );
      } else {
        console.log(`No documents found with email: ${email}`);
      }
    })
    .catch((e) => {
      console.error("Error updating user subscription: ", e);
    });
};

// Function to get a user's customer ID based on their email
const getCustomerIDByEmail = async (email: string): Promise<string | null> => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));

  return getDocs(q)
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const customerID = userDoc.data().customer_id as string; // Assuming customer_id is always a string
        console.log(`Found customer ID: ${customerID} for email: ${email}`);
        return customerID; // Resolves the Promise with the customerID
      } else {
        console.log(`No documents found with email: ${email}`);
        return null; // Resolves the Promise with null
      }
    })
    .catch((e) => {
      console.error("Error fetching customer ID: ", e);
      return null; // Resolves the Promise with null in case of error
    });
};

const getSubscriptionIDByEmail = async (
  email: string
): Promise<string | null> => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));

  return getDocs(q)
    .then((querySnapshot) => {
      if (!querySnapshot.empty) {
        // Assuming each email is unique and only returns one document
        const userDoc = querySnapshot.docs[0];
        const subscriptionID = userDoc.data().subscription_id; // Make sure the field name matches what's in Firestore
        console.log(
          `Found subscription ID: ${subscriptionID} for email: ${email}`
        );
        return subscriptionID; // Resolves the Promise with the subscriptionID
      } else {
        console.log(`No documents found with email: ${email}`);
        return null; // Resolves the Promise with null
      }
    })
    .catch((e) => {
      console.error("Error fetching subscription ID: ", e);
      return null; // Resolves the Promise with null in case of error
    });
};

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const isSubscriptionValid = async (email: string): Promise<boolean> => {
  const user = await getCurrentUser();
  const authToken = await user?.getIdToken();

  return getSubscriptionIDByEmail(email)
    .then((subscriptionID) => {
      if (subscriptionID) {
        return fetch(
          `${HTTP_PROTOCAL}://${process.env.REACT_APP_PAYMENTS_API}/api/account/subscription/validity`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            alert("HJO");
            console.log("REPONSE:", response.json());
            return response.json();
          })
          .then((data) => data.isValid)
          .catch((error) => {
            console.error("Error:", error);
            return false;
          });
      } else {
        console.log(`No subscription ID found for email: ${email}`);
        return Promise.resolve(false);
      }
    })
    .catch((e) => {
      console.error("Error fetching subscription validity: ", e);
      return false;
    });
};

export {
  addUserFirestore,
  removeUserFirebase,
  updateUserSubscription,
  getCustomerIDByEmail,
  getSubscriptionIDByEmail,
  isSubscriptionValid,
};
