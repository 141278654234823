/* Data Models */
import { PDFFile } from "../../model/DisclosureAnalysis/PDFFile";

/* Functions and Utils */
import { deleteDB, IDBPDatabase } from "idb";


export default class PDFCache {
  db: IDBPDatabase<unknown> | null = null;

  constructor(db: IDBPDatabase<unknown>) {
    this.db = db;
  }

  async close(name: string) {
    await deleteDB(name);
  }

  async store(pdf: PDFFile, file_id: string) {
    try {
      if (this.db != null) {
        await this.db.put("pdfs", pdf.data, file_id); // store PDFFile.blob
        localStorage.setItem(file_id, pdf.fileName); // store PDFFile.fileName
      }
    } catch (error) {
      console.error("Error storing PDF in IndexedDB:", error);
    }
  }

  async get(file_id: string): Promise<PDFFile | null> {
    if (this.db != null) {
      const pdfBlob = await this.db.get("pdfs", file_id);  // get PDFFile.blob
      const pdfFilename = localStorage.getItem(file_id);  // get PDFFile.fileName
      if (pdfBlob && pdfFilename) {
        return { fileName: pdfFilename, data: pdfBlob, file_id: file_id };
      }
    }
    return null
  }
}