import React, { useState } from "react";
import Navbar from "../../Common/Navbar";
import Lottie from "react-lottie";

import { useNavigate } from "react-router-dom";
import {
  logInEmailPassword,
  resetPassword,
} from "../../../firebase/authentication/functions";

import buttonImage from "../../../assets/continue-button.svg";
import buttomImageDisabled from "../../../assets/continue-button-disabled.svg";
import tryAgainRed from "../../../assets/try-again-red.svg";
import LoadingLottie from "../../../assets/circle-loading.json";
import {
  query,
  collection,
  where,
  getDocs,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firestore/firestore";
import "./UpdatePassword.css";

/* 
================================
API
================================
*/
type AuthorizedRegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordMatch: string;
  termsOfService: boolean;
};

/* 
================================
COMPONENTS
================================
*/

function UpdatePassword() {
  /*
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState<AuthorizedRegisterFormData>({
    firstName: "",
    lastName: "",
    email:
      window.localStorage.getItem("emailForPasswordReset") !== null
        ? String(window.localStorage.getItem("emailForPasswordReset"))
        : "",
    phoneNumber: "",
    password: "",
    passwordMatch: "",
    termsOfService: false,
  });
  const [clickedContinue, setClickedContinue] = useState<boolean>(false);
  const [registrationErrorMsg, setRegistrationErrorMsg] = useState<string>("");

  const navigate = useNavigate();

  /* 
  ================================
  UI FUNCTIONALITY
  ================================
  */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const reloadPage = () => {
    window.location.reload();
  };

  /*
  ================================
  REGISTRATION SUBMISSION
  ================================
  */
  const disableContinue =
    formData.email === "" ||
    formData.password.length <= 6 ||
    formData.password !== formData.passwordMatch;

  const formErrorMessages = [
    { id: 1, message: "Must have an email", condition: formData.email === "" },
    {
      id: 2,
      message: "Password must be more than 6 characters",
      condition: formData.password.length <= 6,
    },
    {
      id: 3,
      message: "Passwords must match",
      condition: formData.password !== formData.passwordMatch,
    },
  ];

  // Filter the list based on a custom condition
  const filteredFormErrorMessages = formErrorMessages.filter(
    (item) => item.condition
  );

  const checkEmailExists = async (email: string): Promise<boolean> => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot: QuerySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleRegistrationSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setClickedContinue(true);
    setRegistrationErrorMsg("");
    const searchParams = new URLSearchParams(document.location.search);
    const code = searchParams.get("oobCode");

    // Step 1: Ensure email and verification link match, create account
    checkEmailExists(formData.email)
      .then((emailExists) => {
        if (emailExists) {
          setRegistrationErrorMsg("");

          // matchingEmailAndLink creates a new user automatically if not already exisiting
          // Update password/phone
          resetPassword(code ? code : "", formData.password)
            .then(() => {
              logInEmailPassword(formData.email, formData.password);
              navigate("/disclosure");
            })
            .catch((error) => {
              setRegistrationErrorMsg(
                "Error creating account. Try again soon." + error
              );
            })
            .catch((error) => {
              // Handle any errors that occur
              setRegistrationErrorMsg("Error updating password: " + error);
              return;
            });
        } else {
          setRegistrationErrorMsg("Error updating password. Try again soon.");
        }
      })
      .catch((error) => {
        setRegistrationErrorMsg(
          "An error occurred while updating the password: " + error
        );
      });
  };

  /*
  ================================
  RENDERING
  ================================
  */
  const lottieLoading = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Navbar />
      <h1 className="register-header">Update Password</h1>
      {!clickedContinue ? (
        <form
          onSubmit={handleRegistrationSubmit}
          className="register-form-wrapper"
        >
          <div className="register-form">
            <div className="register-input-group">
              <label className="register-label-text" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-input-group register-full-width">
              <label className="register-label-text" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-input-group register-full-width">
              <label className="register-label-text" htmlFor="passwordMatch">
                Re-enter Password
              </label>
              <input
                type="password"
                id="passwordMatch"
                name="passwordMatch"
                value={formData.passwordMatch}
                onChange={handleChange}
                placeholder=""
              />
            </div>
          </div>

          {filteredFormErrorMessages.length > 0 ? (
            <ul className="register-form-errors">
              {filteredFormErrorMessages.map((item) => (
                <li key={item.id}>{item.message}</li>
              ))}
            </ul>
          ) : (
            <div />
          )}

          <div className="register-submit-button-wrapper">
            <button
              type="submit"
              className="register-submit-button"
              disabled={disableContinue}
              style={
                disableContinue
                  ? { pointerEvents: "none", cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <img
                src={disableContinue ? buttomImageDisabled : buttonImage}
                alt="Continue"
              />
            </button>
          </div>
        </form>
      ) : registrationErrorMsg === "" ? (
        <Lottie options={lottieLoading} height={200} width={200} />
      ) : (
        <div className="signup-error-block">
          <div className="signup-error-block-header">ERROR</div>
          <p>{registrationErrorMsg}</p>
          <div
            className="signup-error-block-button-wrapper"
            onClick={reloadPage}
          >
            <img
              className="signup-error-block-button"
              src={tryAgainRed}
              alt="Try again."
            ></img>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdatePassword;
