import { AnalysisAttributeCategory, SeverityLevel } from "../../../Elements";
import { Reference } from "../../../Reference/Reference";

export enum SellerQuestionnaireAnalysisType {
  CAR_SPQ_0623 = "CAR_SPQ_0623",
  PRDS_SSC_1122 = "PRDS_TDS_1122",
  SELLERQUESTIONNAIREDEFAULT = "SELLERQUESTIONNAIREDEFAULT",
  ADDENDUM = "ADDENDUM",
}

export interface SellerQuestionnaireAnalysisAttribute {
  description: string;
  severity: SeverityLevel;
  category: AnalysisAttributeCategory;
  reference: Reference;
}

export interface SellerQuestionnaireAnalysis {
  items: SellerQuestionnaireAnalysisAttribute[];
}
