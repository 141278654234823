import { DisclosureNavigationSection } from "../DisclosureNavigation/DisclosureNavigationSection";
import DisclosureNavigationToggle from "../DisclosureNavigation/DisclosureNavigationToggle";

import "./DisclosureAnalysisShimmy.css";
import "../PDFViewer/PDFViewer.css";

function PackageInfoShimmy(): JSX.Element {
  return (
    <>
      <div className="disclosure-analysis-shimmy-package-info-property-name" />
      <div className="disclosure-analysis-shimmy-package-info-directory" />
    </>
  );
}

const noopSetSelected: React.Dispatch<
  React.SetStateAction<DisclosureNavigationSection>
> = () => {};

function DisclosureAnalysisViewerShimmy(): JSX.Element {
  return (
    <div className="disclosure-analysis-shimmy-disclosure-analysis-viewers">
      <div className="disclosure-analysis-shimmy-disclosure-analysis-viewers-analysis">
        <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation">
          <DisclosureNavigationToggle
            selected={DisclosureNavigationSection.ANALYSIS}
            setSelected={noopSetSelected}
          />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-master-report" />

          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-section-title" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />

          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-section-title" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />

          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-section-title" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />

          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-section-title" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />
          <div className="disclosure-analysis-shimmy-disclosure-analysis-navigation-analysis-type" />

          <br />
        </div>
        <div className="disclosure-analysis-shimmy-disclosure-component-analysis" />
      </div>
      <div className="disclosure-analysis-shimmy-pdf-viewer">
        <div className="disclosure-analysis-shimmy-pdf-viewer-iframe" />
      </div>
    </div>
  );
}

export function DisclosureAnalysisShimmy(): JSX.Element {
  return (
    <div className="disclosure-analysis-shimmy">
      <PackageInfoShimmy />
      <DisclosureAnalysisViewerShimmy />
    </div>
  );
}
