import {
  HOAFinancialsAnalysis,
  isRatingValue,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/HOAFinancials/HOAFinancials";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";
import "./HOAFinancials.css";

export default function HOAFinancialsAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: HOAFinancialsAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  return (
    <div className="component-analysis-top-level-info">
      <table>
        <tbody>
          <tr>
            <td style={{ minWidth: "150px" }}>Percent Funded:&nbsp;&nbsp;</td>
            <td>
              <span
                className="hoa-financials-percent-funded"
                onClick={() =>
                  setSelectedPage({
                    fileID: analysis.reference?.pdf?.file_id,
                    page: analysis.reference?.page,
                    region: analysis.reference?.region,
                  } as SelectedPage)
                }
              >
                <b>{analysis.percent_funded}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: "150px" }}>Agnsy Rating:&nbsp;&nbsp;</td>
            <td>
              <span
                className={`hoa-financials-rating ${
                  isRatingValue(analysis.rating) ? analysis.rating : ""
                }`}
              >
                {analysis.rating}
                <div className="hoa-financials-rating-explanation">
                  <div className="hoa-financials-rating CAUTION">CAUTION</div>{" "}
                  less than 15% in reserve standing.
                  <br />
                  <div
                    className="hoa-financials-rating POOR"
                    style={{ marginTop: "2px" }}
                  >
                    POOR
                  </div>{" "}
                  15% - 25% in reserve standing.
                  <br />
                  <div
                    className="hoa-financials-rating FAIR"
                    style={{ marginTop: "2px" }}
                  >
                    FAIR
                  </div>{" "}
                  25% - 45% in reserve standing.
                  <br />
                  <div
                    className="hoa-financials-rating ADEQUATE"
                    style={{ marginTop: "2px" }}
                  >
                    ADEQUATE
                  </div>{" "}
                  45% - 70% in reserve standing.
                  <br />
                  <div
                    className="hoa-financials-rating GOOD"
                    style={{ marginTop: "2px" }}
                  >
                    GOOD
                  </div>{" "}
                  70% - 90% in reserve standing.
                  <br />
                  <div
                    className="hoa-financials-rating EXCELLENT"
                    style={{ marginTop: "2px" }}
                  >
                    EXCELLENT
                  </div>{" "}
                  90%+ in reserve standing.
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
