import React, { useEffect, useRef } from "react";
import { ChatMessage } from "../../../../../../model/DisclosureAnalysis/Chat/Chat";
import { Reference } from "../../../../../../model/DisclosureAnalysis/Reference/Reference";
import agnsyLogo from "../assets/AgnsyALogo.svg";

interface ChatHistoryProps {
  messages: ChatMessage[];
  onReferenceClick: (reference: Reference) => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
  messages,
  onReferenceClick,
}) => {
  const messageEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
    }
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <div className="chat-history" ref={messageEndRef}>
      {messages.map((message, index) => (
        <div
          key={index}
          className={`chat-message ${message.role.toLowerCase()}`}
        >
          {message.role.toLowerCase() === 'system' && (
            <div className="chat-message-content">
              <img src={agnsyLogo} alt="Company Logo" className="chat-logo" />
              <div className="chat-message-text">
                <p>{message.content}</p>
                {message.reference && (
                  <button
                    className="chat-reference-clickable"
                    onClick={() =>
                      message.reference && onReferenceClick(message.reference)
                    }
                  >
                    Page: {message.reference.page}
                  </button>
                )}
              </div>
            </div>
          )}
          {message.role.toLowerCase() === 'user' && (
            <div className="chat-message-user-content">
              <p>{message.content}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ChatHistory;
