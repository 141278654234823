import React from 'react';

interface ChatSuggestionsProps {
  suggestions: string[];
  onSuggestionClick: (suggestion: string) => void;
}

const ChatSuggestions: React.FC<ChatSuggestionsProps> = ({ suggestions, onSuggestionClick }) => {
  return (
    <div className="chat-suggestions">
      {suggestions.map((suggestion, index) => (
        <button 
          key={index} 
          onClick={() => onSuggestionClick(suggestion)}
          className="chat-suggestion-button"
        >
          {suggestion}
        </button>
      ))}
    </div>
  );
};

export default ChatSuggestions;