import React, { useState, useCallback } from "react";

import ZipfileIcon from "../../../../assets/zip_drop_icon.svg";

import "./DropZone.css";

/*
================================================================================================
Interface
================================================================================================
*/
interface DropZoneProps {
  uploadingFile: boolean;
  setUploadingFile: (bool: boolean) => void;
  setSelectedFile: (file: File | null) => void;
}

/*
================================================================================================
Component
================================================================================================
*/

function DropZone({ setSelectedFile, setUploadingFile }: DropZoneProps) {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (!isDragging) {
        setIsDragging(true);
      }
    },
    [isDragging]
  );

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (
      !e.relatedTarget ||
      !e.currentTarget.contains(e.relatedTarget as Node)
    ) {
      setIsDragging(false);
    }
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  }, []);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleFileChangeButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  return (
    <div
      className={`file-drop-zone ${isDragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <img
        src={ZipfileIcon}
        alt="Zipfile Icon"
        className="disclosure-upload-zip-file-icon"
      />
      <div className="disclosure-upload-drop-zone-instructions">
        Drop or select your ZIP disclosure package.
      </div>
      <label
        className={`disclosure-upload-select-file-label ${
          isDragging ? "dragging" : ""
        }`}
      >
        <input
          type="file"
          accept=".zip"
          onChange={handleFileChangeButton}
          className="disclosure-upload-select-file"
        />
        Select file
      </label>
    </div>
  );
}

export default DropZone;
