/* Data Models */
import { ComponentAnalysisModule } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import { ESDAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ESD/ESD";
import { FoundationReportAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/FoundationReport/FoundationReport";
import { GeneralInspectionAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/GeneralInspection/GeneralInspection";
import { HOAFinancialsAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/HOAFinancials/HOAFinancials";
import { HOARentalRestrictionsAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/HOARentalRestrictions/HOARentalRestrictions";
import { NHDAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/NHD/NHD";
import { PoolInspectionAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/PoolInspection/PoolInspection";
import { PrelimTitleReportAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/PrelimTtitleReport/PrelimTitleReport";
import { RoofInspectionAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/RoofInspection/RoofInspection";
import { SellerQuestionnaireAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/SellerQuestionnaire/SellerQuestionnaireAnalysis";
import { TermiteInspectionAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/TermiteInspection/TermiteInspection";
import { TransferDisclosureAnalysis } from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/TransferDisclosure/TransferDisclosureAnalysis";
import ComponentAnalysisType from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";
import { SelectedPage } from "../../PDFViewer/PDFViewer";

/* UI Components */
import ESDAnalysisViewer from "./ComponentViewers/ESD/ESD";
import FoundationReportAnalysisViewer from "./ComponentViewers/FoundationReport";
import GeneralInspectionAnalysisViewer from "./ComponentViewers/GeneralInspection";
import HOAFinancialsAnalysisViewer from "./ComponentViewers/HOAFinancials";
import HOARentalRestrictionsAnalysisViewer from "./ComponentViewers/HOARentalRestrictions";
import NHDAnalysisViewer from "./ComponentViewers/NHD";
import PoolInspectionAnalysisViewer from "./ComponentViewers/PoolInspection";
import PreliminaryReportAnalysisViewer from "./ComponentViewers/PreliminaryReport";
import RoofInspectionAnalysisViewer from "./ComponentViewers/RoofInspection";
import SellerQuestionnaireAnalysisViewer from "./ComponentViewers/SellerQuestionnaire";
import TermiteInspectionAnalysisViewer from "./ComponentViewers/TermiteInspection/TermiteInspection";
import TransferDisclosureAnalysisViewer from "./ComponentViewers/TransferDisclosure";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* COMPONENT PROPS */
export interface ComponentViewerSelectorProps<ComponentAnalysisGeneric> {
  component: ComponentAnalysisModule<ComponentAnalysisGeneric>;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}

/* COMPONENT */
export function ComponentViewerSelector<ComponentAnalysisGeneric>({
  component,
  setSelectedPage,
}: ComponentViewerSelectorProps<ComponentAnalysisGeneric>): JSX.Element {
  if (component === null) {
    return <div>Loading...</div>;
  }

  switch (component.type) {
    case ComponentAnalysisType.TRANSFERDISCLOSURE:
      return (
        <TransferDisclosureAnalysisViewer
          analysis={component.analysis as TransferDisclosureAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.SELLERQUESTIONNAIRE:
      return (
        <SellerQuestionnaireAnalysisViewer
          analysis={component.analysis as SellerQuestionnaireAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.GENERALINSPECTION:
      return (
        <GeneralInspectionAnalysisViewer
          analysis={component.analysis as GeneralInspectionAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.TERMITEINSPECTION:
      return (
        <TermiteInspectionAnalysisViewer
          analysis={component.analysis as TermiteInspectionAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.FOUNDATIONREPORT:
      return (
        <FoundationReportAnalysisViewer
          analysis={component.analysis as FoundationReportAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.POOLINSPECTION:
      return (
        <PoolInspectionAnalysisViewer
          analysis={component.analysis as PoolInspectionAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.ROOFINSPECTION:
      return (
        <RoofInspectionAnalysisViewer
          analysis={component.analysis as RoofInspectionAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.HOAFINANCIALS:
      return (
        <HOAFinancialsAnalysisViewer
          analysis={component.analysis as HOAFinancialsAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.HOARENTALRESTRICTIONS:
      return (
        <HOARentalRestrictionsAnalysisViewer
          analysis={component.analysis as HOARentalRestrictionsAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.NHD:
      return (
        <NHDAnalysisViewer
          analysis={component.analysis as NHDAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.PRELIMTITLEREPORT:
      return (
        <PreliminaryReportAnalysisViewer
          analysis={component.analysis as PrelimTitleReportAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    case ComponentAnalysisType.ESD:
      return (
        <ESDAnalysisViewer
          analysis={component.analysis as ESDAnalysis}
          setSelectedPage={setSelectedPage}
        />
      );
    default:
      return <div>Component not supported</div>;
  }
}
