import { getCurrentUser } from "../../../../firebase/authentication/auth";
import { ClassifiedFile } from "../../../../model/DisclosureAnalysis/ClassifiedFile";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

/*
 * Create a new custom analysis
 */
const addCustomAnalysisEndpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/custom-analyzer/add`;
export async function addCustomAnalysis(disclosureID: string) {
  // Get user id
  const user = await getCurrentUser();
  const authToken = await user?.getIdToken();
  const uid = user?.uid;

  await fetch(`${addCustomAnalysisEndpoint}/${uid}/${disclosureID}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
}

/*
 * Rename a custom analysis
 */
const renameCustomAnalysisEndpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/custom-analyzer/rename`;
export async function renameCustomAnalysis(
  disclosureID: string,
  customAnalysisID: string,
  newName: string
) {
  // Get user id
  const user = await getCurrentUser();
  const authToken = await user?.getIdToken();
  const uid = user?.uid;

  await fetch(
    `${renameCustomAnalysisEndpoint}/${uid}/${disclosureID}/${customAnalysisID}/${newName}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
}

/*
 * Associate Files
 */
const associateFilesCustomAnalysisEndpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/custom-analyzer/associate_files`;
export async function associateFilesCustomAnalysis(
  disclosureID: string,
  customAnalysisID: string,
  files: ClassifiedFile[]
) {
  // Get user id
  const user = await getCurrentUser();
  const authToken = await user?.getIdToken();
  const uid = user?.uid || "";

  const formData = new FormData();
  const filesJson = JSON.stringify(files);
  formData.append("files", filesJson);

  await fetch(
    `${associateFilesCustomAnalysisEndpoint}/${uid}/${disclosureID}/${customAnalysisID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }
  );
}

/*
 * Update Prompt
 */
const updatePromptCustomAnalysisEndpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/custom-analyzer/edit_prompt`;
export async function updatePromptCustomAnalysis(
  disclosureID: string,
  customAnalysisID: string,
  prompt: string
) {
  // Get user id
  const user = await getCurrentUser();
  const authToken = await user?.getIdToken();
  const uid = user?.uid || "";

  await fetch(
    `${updatePromptCustomAnalysisEndpoint}/${uid}/${disclosureID}/${customAnalysisID}/${prompt}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
}

/*
 * Perform analysis
 */
const performCustomAnalysisEndpoint = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/custom-analyzer/perform`;
export async function performCustomAnalysis(
  disclosureID: string,
  customAnalysisID: string
) {
  // Get user id
  const user = await getCurrentUser();
  const uid = user?.uid || "";
  const authToken = await user?.getIdToken();

  await fetch(
    `${performCustomAnalysisEndpoint}/${uid}/${disclosureID}/${customAnalysisID}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
}
