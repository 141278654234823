import {
  Auth,
  getWithAuth,
  getCurrentUser,
} from "../firebase/authentication/auth";

const API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const subscriptionStatusCached = async () => {
  const paymentStatus = sessionStorage.getItem("paymentStatus");
  if (paymentStatus) {
    return paymentStatus;
  }

  //if not, get the payment status from the server
  const status = await getSubscriptionStatus();
  return status;
};

const getSubscriptionStatus = async () => {
  const user = await getCurrentUser(Auth);

  if (user) {
    let result = await getWithAuth(
      `${HTTP_PROTOCAL}://${API_BASE_URL}/api/account/subscription/validity`
    );
    let data = await result.json();
    sessionStorage.setItem("paymentStatus", data);
    return data.valid_subscription;
  }
  return false;
};

export { subscriptionStatusCached, getSubscriptionStatus };
