import Navbar from "../../Common/Navbar";

import "./NotFound.css";

function NotFound() {
  return (
    <div>
      <Navbar />
      <h1 className="not-found-header">404</h1>
      <div className="not-found-body">Page not found</div>
    </div>
  );
}

export default NotFound;
