import ComponentAnalysisType, {
  ComponentTypeDisplayMapping,
} from "../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";

import "./ComponentAnalysisTitle.css";

interface ComponentAnalysisTitleProps {
  type: ComponentAnalysisType;
}

export default function ComponentAnalysisTitle({
  type,
}: ComponentAnalysisTitleProps): JSX.Element {
  if (type === null) {
    return <div>Unknown title.</div>;
  }

  return (
    <div className="component-analysis-title">
      {ComponentTypeDisplayMapping[type]}
    </div>
  );
}
