/* React */
import React, { useEffect, useRef, useState } from "react";

/* UI Components */
import { Document, Page, pdfjs } from "react-pdf";

/* Data Models */
import { BoundingBox } from "../../../../../model/DisclosureAnalysis/Reference/BoundingBox";
import { PDFFile } from "../../../../../model/DisclosureAnalysis/PDFFile";

/* Utility Functions */
import { blobToUint8Array } from "./utils";

/* Assets */
import arrowIcon from "../AnalysisModuleViewer/assets/SectionArrow.svg";
import arrowIconGreyed from "../AnalysisModuleViewer/assets/SectionArrowGreyed.svg";

/* Styling */
import "./PDFViewer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export interface SelectedPage {
  fileID: string;
  page: number;
  region?: BoundingBox;
}

export interface PDFViewerProps {
  pdfs: PDFFile[] | null;
  selected: SelectedPage | null;
}

export interface PageDimensions {
  width: number;
  height: number;
}

function PDFViewer({ pdfs, selected }: PDFViewerProps): JSX.Element {
  /**
   * Constants
   */
  const pageContainerRef = useRef<HTMLDivElement>(null);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumberPages(numPages);
    if (pageContainerRef.current) {
      setPageDimensions({
        width: pageContainerRef.current.clientWidth,
        height: pageContainerRef.current.clientHeight,
      });
      console.log(
        pageContainerRef.current.clientWidth,
        pageContainerRef.current.clientHeight
      );
    }
  }

  /**
   * State Variables
   */
  const [pageDimensions, setPageDimensions] = useState<PageDimensions>({
    width: 0,
    height: 0,
  });
  const [numberPages, setNumberPages] = useState<number>(
    Number.MAX_SAFE_INTEGER
  );
  const [centeredContentRef] = useState<HTMLDivElement | null>(null);
  const [uint8ArrayContent, setUint8ArrayContent] = useState<Uint8Array | null>(
    null
  );
  const [currentPDFIndex, setCurrentPDFIndex] = useState<number>(0);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [carouselOffset, setCarouselOffset] = useState(50);
  const [boundingBoxStyle, setBoundingBoxStyle] =
    useState<React.CSSProperties | null>(null);

  /**
   * Effects
   */
  // Set selected PDF
  useEffect(() => {
    if (pdfs && selected) {
      const selectedIndex = pdfs.findIndex(
        (pdf) => pdf.file_id === selected.fileID
      );
      if (selectedIndex !== -1) {
        setCurrentPDFIndex(selectedIndex);
      }
      setCurrentPageNumber(selected.page);
    }
    return () => {
      setCurrentPDFIndex(0);
    };
  }, [pdfs, selected]);

  // Fetch PDFs
  useEffect(() => {
    const fetchData = async () => {
      if (
        pdfs &&
        pdfs.length > 0 &&
        currentPDFIndex >= 0 &&
        currentPDFIndex < pdfs.length
      ) {
        const currentPDF = pdfs[currentPDFIndex];
        try {
          const uint8Array = await blobToUint8Array(currentPDF.data);
          setUint8ArrayContent(uint8Array);
        } catch (error) {
          console.error("Error converting Blob to Uint8Array:", error);
        }
      }
    };

    fetchData();
  }, [pdfs, currentPDFIndex, selected]);

  // Set bounding box
  useEffect(() => {
    if (selected?.region && pageDimensions.width && pageDimensions.height) {
      const { top_left, bottom_right } = selected.region;
      const style = {
        position: "absolute",
        border: "3px solid black",
        top: `calc(${
          top_left.y *
          (centeredContentRef?.offsetHeight || pageDimensions.height)
        }px - 5px)`,
        left: `calc(${
          top_left.x * (centeredContentRef?.offsetWidth || pageDimensions.width)
        }px - 5px)`,
        width: `calc(${
          (bottom_right.x - top_left.x) *
          (centeredContentRef?.offsetWidth || pageDimensions.width)
        }px - 20px)`,
        height: `calc(${
          (bottom_right.y - top_left.y) *
          (centeredContentRef?.offsetHeight || pageDimensions.height)
        }px + 10px)`,
        pointerEvents: "none",
      } as React.CSSProperties;
      setBoundingBoxStyle(style);
    } else {
      setBoundingBoxStyle(null);
    }
  }, [selected, centeredContentRef, pageDimensions]);

  // Update dimensions
  const updateDimensions = () => {
    if (pageContainerRef.current) {
      setPageDimensions({
        width: pageContainerRef.current.clientWidth,
        height: pageContainerRef.current.clientHeight,
      });
      setBoundingBoxStyle(null);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    updateDimensions();

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  /**
   * UI Functionality
   */
  const slideCarouselLeft = () => {
    setCarouselOffset(carouselOffset + 25);
  };

  const slideCarouselRight = () => {
    setCarouselOffset(carouselOffset - 25);
  };

  const goToPreviousPDF = () => {
    if (currentPDFIndex > 0) {
      setCurrentPDFIndex(currentPDFIndex - 1);
      slideCarouselLeft();
      setBoundingBoxStyle(null);
    }
  };

  const goToNextPDF = () => {
    if (pdfs && currentPDFIndex < pdfs.length - 1) {
      setCurrentPDFIndex(currentPDFIndex + 1);
      slideCarouselRight();
      setBoundingBoxStyle(null);
    }
  };

  const goToPreviousPage = () => {
    if (currentPageNumber > 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      setBoundingBoxStyle(null);
    }
  };

  const goToNextPage = () => {
    if (currentPageNumber < numberPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      setBoundingBoxStyle(null);
    }
  };

  const file = React.useMemo(() => {
    if (uint8ArrayContent) {
      return { data: uint8ArrayContent };
    }
    return null;
  }, [uint8ArrayContent]);

  /**
   * RENDERING
   */
  if (!uint8ArrayContent) {
    return (
      <div className="disclosure-analysis-shimmy-pdf-viewer">
        <div className="disclosure-analysis-shimmy-pdf-viewer-iframe" />
      </div>
    );
  }
  if (!pdfs || pdfs.length === 0) {
    return <div className="pdf-viewer no-files">No PDFs to show</div>;
  } else {
    const currentPDF = pdfs[currentPDFIndex];
    const previousPDF = pdfs[currentPDFIndex - 1];
    const nextPDF = pdfs[currentPDFIndex + 1];

    return (
      <div className="pdf-viewer" ref={pageContainerRef}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div
              style={{
                height: pageDimensions.height,
                width: pageDimensions.width,
                borderRadius: "1.3vh",
                marginTop: "26px",
              }}
              className="disclosure-analysis-shimmy-pdf-viewer"
            >
              <div className="disclosure-analysis-shimmy-pdf-viewer-iframe" />
            </div>
          }
        >
          <div
            className="pdf-viewer-filenames-container"
            style={{
              transform: `translateX(${carouselOffset}%)`,
              marginRight: `calc(1 * ${carouselOffset}%)`,
              marginLeft: `calc(-1 * ${carouselOffset}%)`,
              overflow: "hidden",
            }}
          >
            <div
              className={`pdf-viewer-filename-previous ${
                currentPDFIndex === 0 && "disabled"
              }`}
              onClick={goToPreviousPDF}
            >
              {previousPDF?.fileName}
            </div>
            <div className="pdf-viewer-filename-current">
              {currentPDF.fileName}
            </div>
            <div
              className={`pdf-viewer-filename-next ${
                currentPDFIndex === pdfs.length - 1 && "disabled"
              }`}
              onClick={goToNextPDF}
            >
              {nextPDF?.fileName}
            </div>
          </div>
          <div className="pdf-viewer-content">
            <div className="pdf-viewer-container">
              <Page
                pageNumber={currentPageNumber}
                height={pageDimensions.height}
                className="pdf-viewer-container-page"
              />
              {boundingBoxStyle && <div style={boundingBoxStyle} />}
            </div>
            <div className="pdf-viewer-page-controls">
              <div className="pdf-viewer-page-controls-page-number-wrapper">
                <div className="pdf-viewer-page-controls-page-number">
                  {currentPageNumber}
                </div>
              </div>
              {/* Previous Page */}
              <div
                onClick={goToPreviousPage}
                className={`pdf-viewer-page-controls-page-up-wrapper ${
                  currentPageNumber === 1 ? "disabled" : ""
                }`}
              >
                <img
                  src={currentPageNumber === 1 ? arrowIconGreyed : arrowIcon}
                  alt="Next page"
                  className="pdf-viewer-page-controls-page-up"
                />
              </div>
              {/* Next Page */}
              <div
                onClick={goToNextPage}
                className={`pdf-viewer-page-controls-page-down-wrapper ${
                  currentPageNumber >= numberPages ? "disabled" : ""
                }`}
              >
                <img
                  src={
                    currentPageNumber >= numberPages
                      ? arrowIconGreyed
                      : arrowIcon
                  }
                  alt="Next page"
                  className={`pdf-viewer-page-controls-page-down ${
                    currentPageNumber >= numberPages ? "disabled" : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </Document>
      </div>
    );
  }
}

function areEqual(prevProps: PDFViewerProps, nextProps: PDFViewerProps) {
  return (
    prevProps.pdfs === nextProps.pdfs &&
    prevProps.selected === nextProps.selected
  );
}

export default React.memo(PDFViewer, areEqual);
