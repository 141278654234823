/* React */
import React, {
  useState,
  KeyboardEvent as ReactKeyboardEvent,
  useEffect,
} from "react";

/* Data Models */
import { SelectedPage } from "../../PDFViewer/PDFViewer";
import { DisclosurePackage } from "../../../../../../model/DisclosureAnalysis/DisclosurePackage";
import { PDFFile } from "../../../../../../model/DisclosureAnalysis/PDFFile";

/* UI Components */

/* Functions and Utils */
import PDFCache from "../../../../../../utils/cache/pdf";
import {
  CustomComponentAnalysis,
  CustomComponentAnalysisItem,
  CustomComponentAnalysisModule,
} from "../../../../../../model/DisclosureAnalysis/AnalysisModules/CustomComponentAnalysisModule";
import {
  associateFilesCustomAnalysis,
  performCustomAnalysis,
  renameCustomAnalysis,
  updatePromptCustomAnalysis,
} from "../../../api/custom";
import { fetchPdfFile } from "../../../api";

/* Styling */
import TextEditIcon from "../../../../../../assets/text-edit.png";
import sectionArrowIcon from "../assets/SectionArrow.svg";
import { ReactComponent as RemoveIcon } from "../../../../../../assets/remove.svg";
import { ReactComponent as AddIcon } from "../../../../../../assets/add.svg";

import "./CustomComponent.css";
import { ClassifiedFile } from "../../../../../../model/DisclosureAnalysis/ClassifiedFile";
import {
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../model/DisclosureAnalysis/Elements";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * PROPS: for the CustomModuleViewer component.
 */
export interface CustomModuleViewerProps {
  disclosurePackage: DisclosurePackage;
  customAnalysisModule: CustomComponentAnalysisModule;
  pdfCache: PDFCache | null;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}

/**
 * COMPONENT: displays the analysis of a component based on its status.
 */
export default function CustomModuleViewer({
  disclosurePackage,
  customAnalysisModule,
  pdfCache,
  setSelectedPage,
}: CustomModuleViewerProps): JSX.Element {
  const customAnalysis =
    Object.keys(customAnalysisModule.analysis).length === 0
      ? null
      : (customAnalysisModule.analysis as CustomComponentAnalysis);

  const performAnalysis = () => {
    performCustomAnalysis(disclosurePackage.id, customAnalysisModule.id);
  };

  return (
    <div className="analysis-module">
      <CustomModuleTitle
        initialName={customAnalysisModule.name}
        disclosureID={disclosurePackage.id}
        customAnalysisID={customAnalysisModule.id}
      />
      <CustomModuleFilesAssociator
        initialAssociatedFiles={customAnalysisModule.associated_files}
        disclosurePackage={disclosurePackage}
        customAnalysisID={customAnalysisModule.id}
        pdfCache={pdfCache}
      />
      <CustomModulePromptEditor
        initialPrompt={customAnalysisModule.prompt}
        disclosureID={disclosurePackage.id}
        customAnalysisID={customAnalysisModule.id}
      />
      <button
        disabled={
          customAnalysisModule.prompt === null ||
          customAnalysisModule.associated_files.length === 0
        }
        onClick={() => performAnalysis()}
      >
        Analyze
      </button>
      <CustomModuleAnalysisViewer
        analysis={customAnalysis}
        setSelectedPage={setSelectedPage}
      />
    </div>
  );
}

/*
 * Title
 */
function CustomModuleTitle({
  initialName,
  disclosureID,
  customAnalysisID,
}: {
  initialName: string;
  disclosureID: string;
  customAnalysisID: string;
}): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(initialName);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const onClick = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (event: ReactKeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      renameCustomAnalysis(disclosureID, customAnalysisID, name);
    }
  };

  return (
    <div className="custom-component-title">
      <div className="custom-component-title-text">
        {isEditing ? (
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
            style={{
              fontSize: "inherit",
              fontWeight: "inherit",
              width: "100%",
              border: "none",
              background: "transparent",
              outline: "none",
            }}
          />
        ) : (
          <div className="custom-component-title-text" onClick={onClick}>
            {name}
          </div>
        )}
      </div>
      &nbsp;&nbsp;&nbsp;
      <>
        {isEditing ? (
          <></>
        ) : (
          <img
            className="custom-component-title-text-edit"
            src={TextEditIcon}
            alt="Property name edit button."
            onClick={() => setIsEditing(true)}
          />
        )}
      </>
    </div>
  );
}

/*
 * Files associator
 */
function CustomModuleFilesAssociator({
  initialAssociatedFiles,
  disclosurePackage,
  customAnalysisID,
  pdfCache,
}: {
  initialAssociatedFiles: ClassifiedFile[];
  disclosurePackage: DisclosurePackage;
  customAnalysisID: string;
  pdfCache: PDFCache | null;
}): JSX.Element {
  /**
   * State Variables
   */
  const [isOpen, setIsOpen] = useState(false);
  const [pdfFiles, setPDFFiles] = useState<PDFFile[]>([]);
  const [associatedFiles, setAssociatedFiles] = useState<ClassifiedFile[]>([]);
  const [selectedAssociatedFiles, setSelectedAssociatedFiles] = useState<
    ClassifiedFile[]
  >([]);

  /**
   * UI Functionality
   */
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function isAssociatedFile(pdfFile: PDFFile): boolean {
    return selectedAssociatedFiles.some(
      (classifiedFile) => classifiedFile.pdf.file_id === pdfFile.file_id
    );
  }

  const toggleFileSelection = (fileID: string) => {
    setSelectedAssociatedFiles((prevSelectedFiles) => {
      const fileExists = prevSelectedFiles.some(
        (file) => file.pdf.file_id === fileID
      );

      if (fileExists) {
        // remove it
        return prevSelectedFiles.filter((file) => file.pdf.file_id !== fileID);
      } else {
        // add it
        const fileToAdd =
          pdfFiles.find((file) => file.file_id === fileID) || null;
        if (fileToAdd === null) {
          return prevSelectedFiles;
        }
        const newClassifiedFile: ClassifiedFile = {
          pdf: {
            uid: disclosurePackage.uid,
            disclosure_id: disclosurePackage.id,
            file_id: fileID,
          },
          pages: null,
          subtype: null,
        };
        return [...prevSelectedFiles, newClassifiedFile];
      }
    });
  };

  /**
   * API Calls
   */
  const fetchPDFs = async () => {
    if (
      disclosurePackage.files !== undefined &&
      disclosurePackage.files.length > 0 &&
      pdfCache
    ) {
      try {
        const newPdfFiles = await Promise.all(
          disclosurePackage.files.map(async (pdfReferneceFiresore) => {
            try {
              const cachedPDF = await pdfCache.get(
                pdfReferneceFiresore.file_id
              );
              if (cachedPDF) {
                return cachedPDF;
              } else {
                // Fetch the PDF if it's not in the cache
                const fetchedPDF = await fetchPdfFile(pdfReferneceFiresore);
                pdfCache.store(fetchedPDF, pdfReferneceFiresore.file_id);
                return fetchedPDF;
              }
            } catch (error) {
              console.error(
                "Error fetching PDF CUSTOM FILES ASSOCIATOR",
                error
              );
              return null;
            }
          })
        );

        const filteredPdfFiles = newPdfFiles.filter(
          (pdfFile) => pdfFile !== null
        ) as PDFFile[];
        setPDFFiles(filteredPdfFiles);
      } catch (error) {
        console.error("Error fetching PDF PDF CUSTOM FILES ASSOCIATOR:", error);
      }
    }
  };

  const confirmUpdatedClassifiedFiles = () => {
    associateFilesCustomAnalysis(
      disclosurePackage.id,
      customAnalysisID,
      selectedAssociatedFiles
    );
    setAssociatedFiles(selectedAssociatedFiles);
    setIsOpen(false);
  };

  /**
   * Hooks
   */
  useEffect(() => {
    setAssociatedFiles(initialAssociatedFiles);
    setSelectedAssociatedFiles(initialAssociatedFiles);
  }, [initialAssociatedFiles]);

  useEffect(() => {
    fetchPDFs();

    // Cleanup function
    return () => {
      setPDFFiles([]);
    };
  }, [disclosurePackage.files, pdfCache]);

  /*
   * Rendering
   */
  return (
    <div className="custom-component-files-associator">
      <button
        onClick={toggleDropdown}
        className={`custom-component-files-associator-toggle-button ${
          isOpen ? "open" : ""
        }`}
      >
        <span className="custom-component-files-associator-toggle-button-text">
          Choose files to analyze
        </span>
        {!arraysAreEqual(selectedAssociatedFiles, associatedFiles) ? (
          <div
            className="custom-component-files-associator-confirm"
            onClick={() => confirmUpdatedClassifiedFiles()}
          >
            UPDATE
          </div>
        ) : (
          <svg
            className={`custom-component-files-associator-toggle-arrow ${
              isOpen ? "open" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="5 8 14 10"
            width="14"
            height="14"
          >
            <path
              fill="white"
              d="M7.41 16.58L12 12l4.59 4.59L18 15.17l-6-6-6 6z"
            />
          </svg>
        )}
      </button>
      <div
        className={`custom-component-files-associator-dropdown ${
          isOpen ? "open" : ""
        }`}
      >
        {pdfFiles.map((file, index) => (
          <div key={file.fileName}>
            <div
              key={file.fileName}
              className="custom-component-files-associator-item"
            >
              {isAssociatedFile(file) ? (
                <div
                  className="custom-component-files-associator-item-content"
                  onClick={() => toggleFileSelection(file.file_id)}
                >
                  <span style={{ fontWeight: "500" }}>{file.fileName}</span>
                  <RemoveIcon className="custom-component-files-associator-remove" />
                </div>
              ) : (
                <div
                  className="custom-component-files-associator-item-content"
                  onClick={() => toggleFileSelection(file.file_id)}
                >
                  <span style={{ fontWeight: "500" }}>{file.fileName}</span>
                  <AddIcon className="custom-component-files-associator-add" />
                </div>
              )}
            </div>
            <div className="custom-component-files-associator-item-divider" />
          </div>
        ))}
      </div>
    </div>
  );
}

function arraysAreEqual(
  arr1: ClassifiedFile[],
  arr2: ClassifiedFile[]
): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every(
    (file, index) => file.pdf.file_id === arr2[index].pdf.file_id
  );
}

/*
 * Prompt Editor
 */
function CustomModulePromptEditor({
  initialPrompt,
  disclosureID,
  customAnalysisID,
}: {
  initialPrompt: string | null;
  disclosureID: string;
  customAnalysisID: string;
}): JSX.Element {
  /**
   * State Variables
   */
  const [prompt, setPrompt] = useState<string | null>(initialPrompt);

  /**
   * Hooks
   */
  useEffect(() => {
    setPrompt(initialPrompt);
  }, [initialPrompt]);

  /**
   * API Calls
   */
  const handleKeyDown = (event: ReactKeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && prompt !== null) {
      updatePromptCustomAnalysis(disclosureID, customAnalysisID, prompt);
    }
  };

  /*
   * Rendering
   */
  return (
    <div className="custom-component-prompt-editor">
      Prompt: &nbsp;&nbsp;
      <input
        type="text"
        value={prompt ?? ""}
        onChange={(e) => setPrompt(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="What is the analysis about?"
        className={!prompt ? "custom-component-prompt-editor-placeholder" : ""}
      />
    </div>
  );
}

/*
 * Prompt Editor
 */
function CustomModuleAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: CustomComponentAnalysis | null;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  /**
   * Constants
   */
  const sectionTitle = "Analysis Items";

  /**
   * State Variables
   */
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({ "Red Flags": true, Pertinent: false, Other: false });

  /**
   * UI Functionality
   */
  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  /**
   * Rendering
   */
  const renderItems = (items: CustomComponentAnalysisItem[]) => (
    <div className="component-analysis-section-list">
      {items
        .sort(
          (a, b) =>
            SeverityLevelOrder[a.severity] - SeverityLevelOrder[b.severity]
        )
        .map((item: CustomComponentAnalysisItem, index: number) => (
          <div
            key={index}
            className="component-analysis-section-item"
            onClick={() =>
              setSelectedPage({
                fileID: item.reference?.pdf?.file_id,
                page: item.reference?.page,
                region: item.reference?.region,
              } as SelectedPage)
            }
          >
            <div className="component-analysis-section-item-description bold">
              {item.description}
            </div>
            <div>
              {item.severity !== SeverityLevel.NOTAPPLICABLE && (
                <div className={`severity-box ${item.severity}`}>
                  {item.severity}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );

  if (analysis === null) {
    return (
      <div className="custom-component-analysis">
        Waiting to start analysis.
      </div>
    );
  }

  return (
    <div className="custom-component-analysis">
      <div className="component-analysis-section">
        <div
          className="component-analysis-section-header"
          onClick={() => toggleSection(sectionTitle)}
        >
          <div>
            <img
              src={sectionArrowIcon}
              alt="toggle"
              className={`component-analysis-section-header-arrow ${
                expandedSections[sectionTitle] ? "expanded" : "collapsed"
              }`}
            />
            {sectionTitle}
          </div>
          <div className="component-analysis-section-header-brief">
            {sectionTitle.length}
          </div>
        </div>
        {expandedSections[sectionTitle] && renderItems(analysis.items)}
      </div>
    </div>
  );
}
