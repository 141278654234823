import React, { useState } from "react";
import arrowIcon from "../../../assets/SectionArrow.svg";
import "./TermiteInspection.css";
import {
  TermiteInspectionAnalysis,
  TermiteInspectionStandardSection,
  TermiteInspectionFurtherItemsSection,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/TermiteInspection/TermiteInspection";
import {
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

export default function TermiteInspectionAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: TermiteInspectionAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({
    "Section 1: Active infestations": true,
    "Section 2: Conditions to cause infestation": false,
    "Further Items": false,
  });

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  const renderItems = (items: TermiteInspectionStandardSection["items"]) => (
    <div className="component-analysis-section-list">
      {items
        .sort(
          (a, b) =>
            SeverityLevelOrder[a.severity] - SeverityLevelOrder[b.severity]
        )
        .map((item, index) => (
          <div
            key={index}
            className="component-analysis-section-item"
            onClick={() =>
              setSelectedPage({
                fileID: item.reference?.pdf?.file_id,
                page: item.reference?.page,
                region: item.reference?.region,
              } as SelectedPage)
            }
          >
            <div className="component-analysis-section-item-description bold">
              {item.issue}
            </div>
            <div>
              <div
                className="component-analysis-section-item-cost"
                style={{ marginTop: "8px" }}
              >
                {item.cost}
              </div>
              <div>
                {item.severity !== SeverityLevel.NOTAPPLICABLE && (
                  <div className={`severity-box ${item.severity}`}>
                    {item.severity}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );

  const renderSection = (
    section:
      | TermiteInspectionStandardSection
      | TermiteInspectionFurtherItemsSection,
    title: string
  ) => (
    <div className="component-analysis-section">
      <div
        className="component-analysis-section-header"
        onClick={() => toggleSection(title)}
      >
        <div>
          <img
            src={arrowIcon}
            alt="toggle"
            className={`component-analysis-section-header-arrow ${
              expandedSections[title] ? "expanded" : "collapsed"
            }`}
          />
          {title}
        </div>
        <div className="component-analysis-section-header-brief">
          Total Cost: {section.total_cost}
        </div>
      </div>
      {expandedSections[title] && renderItems(section.items)}
    </div>
  );

  return (
    <div>
      {renderSection(analysis.section_1, "Section 1: Active infestations")}
      {renderSection(
        analysis.section_2,
        "Section 2: Conditions to cause infestation"
      )}
      {renderSection(analysis.further_items, "Further Items")}
    </div>
  );
}
