import React, { useState } from "react";
import Lottie from "react-lottie";

import { useNavigate } from "react-router-dom";
import { logInEmailPassword } from "../../../firebase/authentication/functions";

import buttonImage from "../../../assets/continue-button.svg";
import buttonImageDisabled from "../../../assets/continue-button-disabled.svg";
import tryAgainRed from "../../../assets/try-again-red.svg";
import LoadingLottie from "../../../assets/circle-loading.json";
import NavbarUnauthorized from "../../Common/Navbar/NavbarUnauthorized";

import "./Login.css";

export default function Login() {
  /* 
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [clickedContinue, setClickedContinue] = useState<boolean>(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const navigate = useNavigate();

  /* 
  ================================
  UI Functionality
  ================================
  */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const reloadPage = () => {
    window.location.reload();
  };

  /* 
  ================================
  SUBMISSION
  ================================
  */
  const disableSubmission = formData.email === "" || formData.password === "";
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(formData);
    setClickedContinue(true);

    logInEmailPassword(formData.email, formData.password)
      .then(() => {
        console.log("Successful login.");
        navigate("/disclosure");
      })
      .catch((error) => {
        console.error("Error logging in", error);
        if (
          error.code === "auth/invalid-credential" ||
          error.code === "auth/user-not-found"
        ) {
          setLoginErrorMessage("Invalid email and password combination.");
        } else {
          setLoginErrorMessage("Unexpected error when logging in.");
        }
      });
  };

  /* 
  ================================
  RENDERING
  ================================
  */
  const lottieLoading = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <NavbarUnauthorized />
      <h1 className="loginHeader">Log in</h1>
      {!clickedContinue ? (
        <form onSubmit={handleSubmit} className="login-form">
          <div className="input-group-login">
            <label className="label-text" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="input-group-login">
            <label className="label-text" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="signup-forgot-div">
            <div className="login-action-group">
              <span className="login-action-text">Don't have an account?</span>
              &nbsp;
              <a
                href={`${window.location.origin}/signup`}
                className="login-tosignup-link"
              >
                Sign Up
              </a>
            </div>
            <div className="login-action-group">
              <span className="login-action-text">Forgot your password?</span>
              &nbsp;
              <a
                href={`${window.location.origin}/reset-password`}
                className="login-tosignup-link"
              >
                Reset Password
              </a>
            </div>
          </div>

          {/* CONTINUE Button */}
          <button
            type="submit"
            className="login-submit-button"
            style={
              disableSubmission
                ? { pointerEvents: "none", cursor: "not-allowed" }
                : { cursor: "pointer" }
            }
          >
            <img
              src={disableSubmission ? buttonImageDisabled : buttonImage}
              alt="Continue"
            />
          </button>
        </form>
      ) : loginErrorMessage !== "" ? (
        <div className="signup-error-block">
          <div className="signup-error-block-header">ERROR</div>
          <p>{loginErrorMessage}</p>
          <div
            className="signup-error-block-button-wrapper"
            onClick={reloadPage}
          >
            <img
              className="signup-error-block-button"
              src={tryAgainRed}
              alt="Try again."
            ></img>
          </div>
        </div>
      ) : (
        <Lottie options={lottieLoading} height={200} width={200} />
      )}
    </div>
  );
}
