import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../../Common/Footer/Footer";
import { termsOfServiceItems } from "./LegalItems";
import "./TermsOfServices.css";

const TermsOfService: React.FC = () => {
  return (
    <div className="terms-of-service-page">
      <Navbar />
      <div className="terms-of-service-page-body">
        
        <div className="terms-of-service-header">
            <div className="terms-of-services-title">Terms of Service</div>
            <div className="terms-of-services-subtitle"><strong>Effective date:</strong> 19th of August, 2024</div>
        </div>
        
        {termsOfServiceItems.map((item, index) => (
          <div key={index} className="terms-of-service-item">
            <div className="terms-of-service-item-title">{item.title}</div>
            <div className="terms-of-service-item-text">{item.text}</div>
          </div>
        ))}
      </div>
      <Footer/>
    </div>
  );
};

export default TermsOfService;