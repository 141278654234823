import React, { useState, useRef, useEffect } from "react";
import TextEditIcon from "../../../../../assets/text-edit.png";
import "./PackageInfo.css";
import { Link } from "react-router-dom";

function PackageInfo({ propertyName }: { propertyName: string }): JSX.Element {
  const [newPropertyName, setNewPropertyName] = useState(propertyName);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setNewPropertyName(propertyName);
  }, [propertyName]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPropertyName(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      // confirm the property name change here
      console.log("Confirmed property name:", newPropertyName);
    }
  };

  const toggleEditing = () => {
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.style.width = "auto";
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  }, [isEditing, newPropertyName]);

  return (
    <div className="package-info">
      <div className="package-info-name">
        {isEditing ? (
          <input
            ref={inputRef}
            className="package-info-property-name"
            value={newPropertyName}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={() => setIsEditing(false)} // Exit edit mode on blur
            autoFocus
          />
        ) : (
          <div className="package-info-property-name" onClick={toggleEditing}>
            {newPropertyName}
          </div>
        )}

        {!isEditing && (
          <img
            className="package-info-property-name-edit"
            src={TextEditIcon}
            alt="Property name edit button."
            onClick={toggleEditing}
          />
        )}
      </div>
      <div className="package-info-directory">
        <Link to="/dashboard" className="package-info-directory-disclosures">
          Disclosures
        </Link>
        <div className="package-info-directory-arrow">&gt;</div>
        <div className="package-info-directory-property-name">
          {newPropertyName}
        </div>
      </div>
    </div>
  );
}

export default PackageInfo;
