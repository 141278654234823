import React, { useEffect, useRef, useState } from "react";
import ChatToggle from "./ChatToggle";
import ChatHistory from "./ChatHistory";
import ChatInput from "./ChatInput";
import ChatSuggestions from "./ChatSuggestions";
import { chatSuggestionsByType } from "../../../../../../model/DisclosureAnalysis/Chat/ChatSuggestions";
import { FirestoreChatRecord } from "../../../../../../model/DisclosureAnalysis/Chat/Chat";
import arrowIcon from "../assets/ChatArrow.svg";

import "./Chat.css";
import { Reference } from "../../../../../../model/DisclosureAnalysis/Reference/Reference";
import ComponentAnalysisType from "../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/Types";

interface ChatViewProps {
  chatRecord: FirestoreChatRecord;
  onSendMessage: (message: string) => void;
  onReferenceClick: (reference: Reference) => void;
  componentType: ComponentAnalysisType;
}

const ChatView: React.FC<ChatViewProps> = ({
  chatRecord,
  onSendMessage,
  onReferenceClick,
  componentType,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLong, setIsLong] = useState(false);
  const chatViewRef = useRef<HTMLDivElement>(null);
  const isChatEmpty = chatRecord.messages.length === 0;

  const toggleChat = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    // Create a MutationObserver to monitor class changes on the disclosure-viewer-navigation element
    const disclosureViewer = document.querySelector(
      ".disclosure-viewer-navigation"
    );
    const observer = new MutationObserver(() => {
      if (disclosureViewer?.classList.contains("expanded")) {
        setIsLong(true);
      } else {
        setIsLong(false);
      }
    });

    if (disclosureViewer) {
      observer.observe(disclosureViewer, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  useEffect(() => {
    if (isExpanded && chatViewRef.current) {
      chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight;
    }
  }, [isExpanded]);

  const suggestions = chatSuggestionsByType[componentType] || [
    "What are the main issues in this report?",
    "Summarize the key findings",
    "Are there any red flags I should be aware of?",
  ];

  return (
    <div
      className={`chat-container ${isExpanded ? "expanded" : "collapsed"} ${
        isLong ? "long" : ""
      }`}
    >
      <div className="chat-toggle" onClick={toggleChat}>
        <img
          src={arrowIcon}
          alt="toggle"
          className={`chat-toggle-arrow ${
            isExpanded ? "expanded" : "collapsed"
          }`}
        />
        <span>AI Chat</span>
      </div>
      {isExpanded && (
        <div className="chat-view" ref={chatViewRef}>
          <ChatHistory
            messages={chatRecord.messages}
            onReferenceClick={onReferenceClick}
          />
          <div className="chat-input-area">
            {isChatEmpty && (
              <ChatSuggestions
                suggestions={suggestions}
                onSuggestionClick={onSendMessage}
              />
            )}
            <ChatInput onSendMessage={onSendMessage} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatView;
