/* React */
import React, { useState, useEffect, useRef } from "react";

/* Styling */
import agentProfile from "../../../../assets/testimony-photo.svg";
import "../Landing.css";
import "./MobileAgentReviews.css";

const MobileAgentReviews = () => {
  const reviews = [
    "“We’ve been using Agnsy to kick start every new project and can’t imagine working without it.”",
    "“Agnsy has transformed our approach to real estate transactions, making everything smoother and more efficient.”",
    "“The detailed analysis provided by Agnsy has been invaluable in closing deals faster.”",
    "“A must-have tool for any real estate professional looking to streamline their workflow.”",
    "“Agnsy's insights are accurate and help us avoid common pitfalls in the industry.”",
  ];

  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const totalReviews = reviews.length;
  const lightbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % totalReviews);
    }, 3000);

    return () => clearInterval(interval);
  }, [totalReviews]);

  useEffect(() => {
    if (lightbarRef.current) {
      const lightbarWidth = lightbarRef.current.parentElement?.offsetWidth || 0;
      const slotWidth = lightbarWidth / totalReviews;
      lightbarRef.current.style.transform = `translateX(${
        currentReviewIndex * slotWidth
      }px)`;
    }
  }, [currentReviewIndex, totalReviews]);

  return (
    <div className="landing-mobile-agent-reviews">
      <div className="landing-mobile-agent-reviews-card">
        <div className="landing-mobile-agent-reviews-card-message">
          {reviews[currentReviewIndex]}
        </div>
        <div className="landing-mobile-agent-reviews-card-profile">
          <img
            src={agentProfile}
            alt="Agent Profile"
            className="landing-mobile-agent-reviews-card-profile-image"
          />
          <div className="landing-mobile-agent-reviews-card-profile-info">
            <strong>Agent Name</strong>
            <div>Top Real Estate Agent</div>
          </div>
        </div>
      </div>
      <div className="landing-mobile-agent-reviews-lightbar-container">
        <div
          ref={lightbarRef}
          className="landing-mobile-agent-reviews-lightbar"
        ></div>
      </div>
    </div>
  );
};

export default MobileAgentReviews;
