import { DisclosureNavigationSection } from "../DisclosureNavigationSection";
import "./DisclosureNavigationToggle.css";

interface DisclosureNavigationToggleProps {
  selected: DisclosureNavigationSection;
  setSelected: React.Dispatch<
    React.SetStateAction<DisclosureNavigationSection>
  >;
}

export default function DisclosureNavigationToggle({
  selected,
  setSelected,
}: DisclosureNavigationToggleProps): JSX.Element {
  return (
    <div className="nav-selector">
      <div
        className={`nav-selector-analysis ${
          selected === DisclosureNavigationSection.ANALYSIS ? "selected" : ""
        }`}
        onClick={() => setSelected(DisclosureNavigationSection.ANALYSIS)}
      >
        Analysis
      </div>
      <div
        className={`nav-selector-files ${
          selected === DisclosureNavigationSection.FILES ? "selected" : ""
        }`}
        onClick={() => setSelected(DisclosureNavigationSection.FILES)}
      >
        Files
      </div>
    </div>
  );
}
