import {
  signInWithEmailAndPassword,
  signOut,
  isSignInWithEmailLink,
  signInWithEmailLink,
  UserCredential,
  updatePassword,
  deleteUser,
  sendPasswordResetEmail,
  confirmPasswordReset,
  sendEmailVerification,
} from "firebase/auth";
import { Auth } from "./auth";

//import user type from firebase

import { User } from "firebase/auth";

/*
================================
EMAIL VERIFICATION Settings
================================
*/
const actionCodeSettings = {
  url: `${window.location.origin}/disclosure`,
  handleCodeInApp: true,
};
const actionCodeSettingsForgotPassword = {
  url: `${window.location.origin}/login`,
  handleCodeInApp: true,
};

/*
================================
SIGN UP
================================
*/
const signUpEmailVerification = (user: User): Promise<void> => {
  return new Promise((resolve, reject) => {
    sendEmailVerification(user, actionCodeSettings)
      .then(() => {
        console.log("Successfuly sent email verification link.");

        // Save form data locally
        window.localStorage.setItem("emailForSignUp", user.email ?? "");

        resolve();
      })
      .catch((error) => {
        console.error("Error sending email verification link:", error);
        reject(error);
      });
  });
};

const ForgotPasswordEmailVerification = (email: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(Auth, email, actionCodeSettingsForgotPassword)
      .then(() => {
        console.log("Successfuly sent email verification link.");

        // Save form data locally
        window.localStorage.setItem("emailForPasswordReset", email);

        resolve();
      })
      .catch((error) => {
        console.error("Error sending email verification link:", error);
        reject(error);
      });
  });
};

const resetPassword = (code: string, newPassword: string) => {
  return confirmPasswordReset(Auth, code, newPassword);
};

const isValidRegistrationLink = (url: string) => {
  return isSignInWithEmailLink(Auth, url);
};

const matchingEmailAndLink = (
  email: string,
  url: string
): Promise<UserCredential> => {
  return signInWithEmailLink(Auth, email, url);
};

/* 
================================
LOGIN
================================
*/
const logInEmailPassword = (
  email: string,
  password: string
): Promise<UserCredential> => {
  return signInWithEmailAndPassword(Auth, email, password);
};

/*
================================
LOGOUT
================================
*/
const logoutCurrentUser = (): Promise<void> => {
  return signOut(Auth)
    .then(() => {
      console.log("Successfuly signed out current user.");
    })
    .catch((error) => {
      console.log("Error signing out current user: ", error);
    });
};

export {
  signUpEmailVerification,
  ForgotPasswordEmailVerification,
  logInEmailPassword,
  logoutCurrentUser,
  isValidRegistrationLink,
  matchingEmailAndLink,
  updatePassword,
  deleteUser,
  confirmPasswordReset,
  resetPassword,
};
