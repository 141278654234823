import React, { useState } from "react";
import "./ShareDisclosureAnalysisButton.css";
import {
  postWithAuth,
  getCurrentUser,
} from "../../../../../firebase/authentication/auth";

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

async function shareDisclosureAnalysis(
  uid: string | null | undefined,
  disclosureId: string | null,
  share_link: string | null,
  showBubble: () => void
) {
  // if share_link is provided, use that
  if (share_link) {
    navigator.clipboard.writeText(share_link);
    showBubble();
    return;
  }
  if (!uid || !disclosureId) {
    uid = (await getCurrentUser())?.uid;
  }
  const result = await postWithAuth(
    `${HTTP_PROTOCAL}://` +
      process.env.REACT_APP_DISCLOSURE_ANALYSIS_API +
      `/api/disclosure/share/${uid}/${disclosureId}`,
    {}
  );
  console.log(result);
  //copy the link to the clipboard
  navigator.clipboard.writeText(result.url);
  //get result body in json
  const resultURL = await result.json();

  if (resultURL.url) {
    navigator.clipboard.writeText(resultURL.url);
    showBubble();
  } else {
    alert("Failed to copy link to clipboard");
  }
}

export default function ShareDisclosureAnalysisButton(props: {
  uid: string | null;
  disclosure_id: string | null;
  share_link: string | null;
}): JSX.Element {
  const [isCopied, setIsCopied] = useState(false);

  const showCopiedBubble = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000); // Hide after 2 seconds
  };

  return (
    <div
      className="share-disclosure-analysis-button"
      onClick={() =>
        shareDisclosureAnalysis(
          props.uid,
          props.disclosure_id,
          props.share_link,
          showCopiedBubble
        )
      }
    >
      Share
      <div
        className={`${
          isCopied
            ? "share-disclosure-analysis-button-coming-copied"
            : "share-disclosure-analysis-button-coming-not"
        }`}
      >
        Link copied to clipboard
      </div>
    </div>
  );
}
