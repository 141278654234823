import React from "react";
import { PDFFile } from "../../../../../../model/DisclosureAnalysis/PDFFile";

import "./DisclosureNavigationFiles.css";

interface DisclosureNavigationFilesProps {
  pdfs: PDFFile[];
}

export default function DisclosureNavigationFiles({
  pdfs,
}: DisclosureNavigationFilesProps): JSX.Element {
  /**
   * CONSTANTS
   */
  const isEmpty = pdfs.length === 0;
  const compareFileNames = (a: PDFFile, b: PDFFile) => {
    const fileNameA = a.fileName;
    const fileNameB = b.fileName;

    const numericValueA = parseInt(fileNameA.match(/\d+/)?.[0] || "0", 10);
    const numericValueB = parseInt(fileNameB.match(/\d+/)?.[0] || "0", 10);
    if (numericValueA !== numericValueB) {
      return numericValueA - numericValueB;
    }

    // If numerical values are equal, compare full filenames lexicographically
    return fileNameA.localeCompare(fileNameB);
  };
  const sortedPDFs = [...pdfs].sort(compareFileNames);

  /**
   * RENDERING
   */
  return (
    <div className="nav-files-viewer">
      {isEmpty ? (
        <DisclosureNavigationFilesShimmy />
      ) : (
        sortedPDFs.map((pdf, index) => (
          <div className="nav-files-viewer-filename" key={index}>
            {pdf.fileName}
          </div>
        ))
      )}
    </div>
  );
}

function DisclosureNavigationFilesShimmy(): JSX.Element {
  return (
    <div className="nav-files-viewer-filename-shimmy">
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
      <div className="nav-files-viewer-filename-shimmy-filename" />
    </div>
  );
}
