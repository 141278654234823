import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getSubscriptionStatus } from "./payment";

const PaidComponent = (Component: React.ComponentType) => {
  const PaidContent: React.FC = () => {
    return <Component />;
  };

  const WithPayment: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const checkSubscriptionStatus = async () => {
        const status = await getSubscriptionStatus();
        if (!status) {
          navigate("/purchase");
        }
      };
      checkSubscriptionStatus();
    }, [navigate]);

    return <PaidContent />;
  };

  return WithPayment;
};
export default PaidComponent;
