/* UI Components */
import ComponentModuleViewer from "./ComponentViewer/ComponentViewer";
import MasterReportViewer from "./MasterReport";
import CustomModuleViewer from "./CustomComponent/CustomComponent";

/* Data Models */
import {
  AnalysisModule,
  AnalysisModuleType,
} from "../../../../../model/DisclosureAnalysis/AnalysisModules/AnalysisModule";
import { MasterReportModule } from "../../../../../model/DisclosureAnalysis/AnalysisModules/MasterReportModule";
import { CustomComponentAnalysisModule } from "../../../../../model/DisclosureAnalysis/AnalysisModules/CustomComponentAnalysisModule";
import { DisclosurePackage } from "../../../../../model/DisclosureAnalysis/DisclosurePackage";
import { SelectedPage } from "../PDFViewer/PDFViewer";

/* Functions and Utils */
import PDFCache from "../../../../../utils/cache/pdf";

/* Styling */
import { ComponentAnalysisModule } from "../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/ComponentAnalysisModule";
import "./AnalysisModuleViewer.css";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/* COMPONENT PROPS */
export interface AnalysisModuleViewerProps {
  analysisModule: AnalysisModule;
  disclosurePackage: DisclosurePackage;
  pdfCache: PDFCache | null;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}

/* COMPONENT */
export default function AnalysisModuleViewer({
  analysisModule,
  disclosurePackage,
  pdfCache,
  setSelectedPage,
}: AnalysisModuleViewerProps): JSX.Element {
  return (
    <>
      {(() => {
        switch (analysisModule.analysis_module_type) {
          case AnalysisModuleType.COMPONENT:
            return (
              <ComponentModuleViewer
                disclosurePackage={disclosurePackage}
                componentAnalysisModule={
                  analysisModule as ComponentAnalysisModule
                }
                pdfCache={pdfCache}
                setSelectedPage={setSelectedPage}
              />
            );
          case AnalysisModuleType.CUSTOM_COMPONENT:
            return (
              <CustomModuleViewer
                disclosurePackage={disclosurePackage}
                customAnalysisModule={
                  analysisModule as CustomComponentAnalysisModule
                }
                pdfCache={pdfCache}
                setSelectedPage={setSelectedPage}
              />
            );
          case AnalysisModuleType.MASTER_REPORT:
            return (
              <MasterReportViewer
                masterAnalysis={analysisModule as MasterReportModule}
                setSelectedPage={setSelectedPage}
              />
            );
          default:
            return <div>Unknown analysis module type</div>;
        }
      })()}
    </>
  );
}
