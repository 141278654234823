export async function blobToUint8Array(blob: Blob): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          const arrayBuffer = reader.result;
          const uint8Array = new Uint8Array(arrayBuffer);
          resolve(uint8Array);
        } else {
          reject(new Error("Failed to read Blob as ArrayBuffer"));
        }
      };
  
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(blob);
    });
  }
  