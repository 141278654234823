import { Routes, Route, Navigate } from "react-router-dom";
import Scroll from "./Scroll";

import { PrivateAnalysis, PublicAnalysis } from "../Pages/DisclosureAnalysis";
import DisclosureUpload from "../Pages/DisclosureUpload";
import FAQ from "../Pages/FAQ/FAQ";
import Register from "../archive-authentication/Register";
import Login from "../archive-authentication/Login";
import BetaNote from "../archive-authentication/BetaNote";
import Signup from "../archive-authentication/Signup";
import NotFound from "../Pages/NotFound";
import VerifyEmailNote from "../archive-authentication/VerifyEmailNote";
import ResetPasswordEmailIntake from "../archive-authentication/ResetPassword/ResetPasswordEmailIntake";
import UpdatePassword from "../archive-authentication/UpdatePassword/UpdatePassword";
import Account from "../archive-authentication/Account/Account";
import CheckoutForm from "../../stripe/CheckoutForm";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { Landing } from "../Pages/Landing/Landing";
import TermsOfService from "../Pages/Legal/TermsOfServices";
import PrivacyPolicy from "../Pages/Legal/PrivacyPolicy";

function App() {
  return (
    <div>
      <Scroll />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/disclosure" element={<DisclosureUpload />} />
        <Route path="/disclosure/analysis" element={<PrivateAnalysis />} />
        <Route path="/disclosure/shared" element={<PublicAnalysis />} />
        <Route path="/faq" element={<FAQ />} />

        <Route path="/signup" element={<Signup />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<Account />} />
        <Route path="/beta-note" element={<BetaNote />} />
        <Route path="/verify-email-note" element={<VerifyEmailNote />} />
        <Route path="/reset-password" element={<ResetPasswordEmailIntake />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/return" element={<Navigate to="/404" replace />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/purchase" element={<CheckoutForm />} />

        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
