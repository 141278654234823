import React, { useState } from "react";

import Navbar from "../../Common/Navbar";
import Footer from "../../Common/Footer/Footer";
import Flag from "./flag.png";
import { faq_items, faq_items_mobile } from "./FAQItems";
import FAQContactForm from './FaqContactForm/ContactForm';
import "./FAQ.css";

const FAQ = () => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const toggleFAQ = (index: number) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div className="faq-page">
            <Navbar />
            <div className="faq-page-body">
                <div className="faq-header">
                    <img 
                        src={Flag}
                        alt="FAQ header icon" 
                        className="faq-header-img" 
                    />
                    <div className="faq-title">Frequently asked questions</div>
                    <div className="faq-subtitle">About product and billings</div>
                </div>

                {/* FAQ lists */}
                <div className="faq-list">
                    {faq_items.map((faq, index) => (
                        <div 
                            key={index} 
                            className="faq-item"
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="faq-question-wrapper">
                                <span className="faq-question">{faq.question}</span>
                                <span className="faq-toggle-icon">
                                    {expandedIndex === index ? "-" : "+"}
                                </span>
                            </div>
                            {expandedIndex === index && (
                                <div className="faq-answer">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* FAQ List Mobile */}
                <div className="faq-list-mobile">
                    {faq_items_mobile.map((faq, index) => (
                        <div 
                            key={index} 
                            className="faq-item-mobile"
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="faq-question-wrapper-mobile">
                                <span className="faq-question-mobile">{faq.question}</span>
                                <span className="faq-toggle-icon-mobile">
                                    {expandedIndex === index ? "-" : "+"}
                                </span>
                            </div>
                            {expandedIndex === index && (
                                <div className="faq-answer-mobile">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="faq-contact-form-page-container" id="faq-contact-form-id">
                    <FAQContactForm/>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default FAQ;