import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD6ZvCHDS2zoVDentDXZpaxjsnxBSIc4sc",
  authDomain: "agnsy-alpha.firebaseapp.com",
  projectId: "agnsy-alpha",
  storageBucket: "agnsy-alpha.appspot.com",
  messagingSenderId: "976533618392",
  appId: "1:976533618392:web:2f1f198a99d4f293dfa1ad",
};
const firebase = initializeApp(firebaseConfig);

export default firebase;
