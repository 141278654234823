import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Auth } from "../../../firebase/authentication/auth"; // Adjust the path based on your project structure
import { useNavigate } from "react-router-dom"; // Ensure react-router-dom is installed
import { startAnalysis } from "../DisclosureUpload/api";
import DashboardSideNav from "./DashboardSideNav/DashboardSideNav"; // Import the DashboardSideNav component
import Navbar from "../../Common/Navbar";
import withAuthorization from "../../../firebase/authentication/AuthorizedComponent";
import withPayment from "../../../stripe/PaidComponent";

interface Disclosure {
  id: string;
  property_name: string;
  deleting?: boolean; // Add the deleting property
}

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;

const Dashboard: React.FC = () => {
  const [names, setNames] = useState<Disclosure[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const navigate = useNavigate(); // For navigating after analysis

  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged(async (user) => {
      if (user) {
        const fetchedNames = await fetchDisclosureNames(user.uid);
        setNames(fetchedNames);
        setIsLoading(false);
      } else {
        console.log("No user logged in");
        setIsLoading(false);
      }
    });

    return () => unsubscribe(); // Cleanup the subscription
  }, []);

  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (selectedFile) {
      setUploading(true); // Show spinner
      startAnalysis(selectedFile, (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          setUploadProgress(percentCompleted);
        }
      })
        .then((disclosureId) => {
          navigate(`/disclosure/analysis?disclosure=${disclosureId}`);
        })
        .catch((error) => {
          console.error("Error starting analysis:", error);
          setSelectedFile(null);
          setUploading(false); // Hide spinner and show DropZone again
          setUploadProgress(0); // Reset progress
        });
    }
  }, [selectedFile, navigate]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("Uploading file:", file.name);
      setUploading(true);
      setSelectedFile(file);
    }
  };

  const fetchDisclosureNames = async (uid: string): Promise<Disclosure[]> => {
    const url = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/all`;
    try {
      const authToken = await Auth.currentUser?.getIdToken();
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: Disclosure[] = await response.json();
      return data;
    } catch (error) {
      console.error("Fetching disclosure names failed:", error);
      return [];
    }
  };

  const handleDelete = async (id: string) => {
    const url = `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/${id}`;
    try {
      const authToken = await Auth.currentUser?.getIdToken();
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // Update the UI by marking the item as deleting
      setNames((prevNames) =>
        prevNames.map((name) =>
          name.id === id ? { ...name, deleting: true } : name
        )
      );
      setTimeout(() => {
        setNames((prevNames) => prevNames.filter((name) => name.id !== id));
      }, 2000); // Duration to show the spinner
    } catch (error) {
      console.error("Deleting disclosure failed:", error);
    }
  };

  const handleDisclosureClick = (id: string) => {
    navigate(`/disclosure/analysis?disclosure=${id}`);
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="DashboardContainer">
        <DashboardSideNav />
        <div className="Dashboard">
          <h1 className="OutfitBold">Disclosure Analyses</h1>
          <label className="OutfitSemibold" style={{ cursor: "pointer" }}>
            New
            <input
              type="file"
              accept=".zip"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </label>
          {isLoading || uploading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : names.length === 0 ? (
            <div className="NoDisclosuresMessage">
              No disclosure packages to show yet, add one with the 'New' button
            </div>
          ) : (
            <ul className="DisclosureList">
              {names.map(({ id, property_name, deleting }) => (
                <li
                  key={id}
                  className={`OutfitMedium ${deleting ? "deleting" : ""}`}
                  onClick={() => handleDisclosureClick(id)}
                >
                  {property_name}
                  {deleting ? (
                    <span className="small-spinner"></span>
                  ) : (
                    <button
                      className="trash-btn"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the disclosure click
                        const user = Auth.currentUser;
                        if (user) {
                          handleDelete(id);
                        }
                      }}
                    ></button>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuthorization(withPayment(Dashboard));
