import React, { useState } from "react";
import arrowIcon from "../../../assets/SectionArrow.svg";
import "./PoolInspection.css";
import {
  PoolInspectionAnalysis,
  PoolInspectionAnalysisItem,
} from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/PoolInspection/PoolInspection";
import {
  AnalysisAttributeCategory,
  SeverityLevel,
  SeverityLevelOrder,
} from "../../../../../../../../model/DisclosureAnalysis/Elements";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

export default function PoolInspectionAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: PoolInspectionAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({ "Red Flags": true, Pertinent: false, Other: false });

  const toggleSection = (sectionName: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }));
  };

  const redFlagItems = analysis.items.filter(
    (item) => item.category === AnalysisAttributeCategory.REDFLAG
  );
  const pertinentItems = analysis.items.filter(
    (item) => item.category === AnalysisAttributeCategory.INFO
  );
  const otherItems = analysis.items.filter(
    (item) => item.category === AnalysisAttributeCategory.DUMP
  );

  const renderItems = (items: PoolInspectionAnalysisItem[]) => (
    <div className="component-analysis-section-list">
      {items
        .sort(
          (a, b) =>
            SeverityLevelOrder[a.severity] - SeverityLevelOrder[b.severity]
        )
        .map((item: PoolInspectionAnalysisItem, index: number) => (
          <div
            key={index}
            className="component-analysis-section-item"
            onClick={() =>
              setSelectedPage({
                fileID: item.reference?.pdf?.file_id,
                page: item.reference?.page,
                region: item.reference?.region,
              } as SelectedPage)
            }
          >
            <div className="component-analysis-section-item-description bold">
              {item.description}
            </div>
            <div>
              {item.severity !== SeverityLevel.NOTAPPLICABLE && (
                <div className={`severity-box ${item.severity}`}>
                  {item.severity}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );

  const renderSection = (title: string, items: PoolInspectionAnalysisItem[]) =>
    items.length > 0 && (
      <div className="component-analysis-section">
        <div
          className="component-analysis-section-header"
          onClick={() => toggleSection(title)}
        >
          <div>
            <img
              src={arrowIcon}
              alt="toggle"
              className={`component-analysis-section-header-arrow ${
                expandedSections[title] ? "expanded" : "collapsed"
              }`}
            />
            {title}
          </div>
          <div className="component-analysis-section-header-brief">
            {items.length}
          </div>
        </div>
        {expandedSections[title] && renderItems(items)}
      </div>
    );

  return (
    <div>
      <div className="component-analysis-top-level-info">
        <table>
          <tbody>
            <tr>
              <td
                className="component-analysis-top-level-info-item"
                style={{ color: analysis.overall_condition, minWidth: "215px" }}
              >
                Overall Condition:&nbsp;&nbsp;
              </td>
              <td
                className="component-analysis-top-level-info-item"
                style={{ color: analysis.overall_condition, minWidth: "215px" }}
              >
                <span className={`condition ${analysis.overall_condition}`}>
                  {analysis.overall_condition}
                </span>
              </td>
            </tr>
            <tr>
              <td className="component-analysis-top-level-info-item">
                Estimated Repair Cost:&nbsp;&nbsp;
              </td>
              <td className="component-analysis-top-level-info-item">
                <span style={{ color: "#c20202" }}>
                  {analysis.estimated_repair_cost}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {renderSection("Red Flags", redFlagItems)}
      {renderSection("Pertinent Info", pertinentItems)}
      {renderSection("Other", otherItems)}
    </div>
  );
}
