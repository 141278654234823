import { Reference } from "../Reference/Reference";

export enum ChatRole {
    USER = "USER",
    SYSTEM = "SYSTEM"
}
  
export interface ChatMessage {
    role: ChatRole;
    content: string;
    reference: Reference | null;
}

export interface FirestoreChatRecord {
    messages: ChatMessage[];
}