import React, { useState } from "react";
import Lottie from "react-lottie";

import { useNavigate } from "react-router-dom";
import {
  logInEmailPassword,
  signUpEmailVerification,
} from "../../../firebase/authentication/functions";

import buttonImage from "../../../assets/continue-button.svg";
import buttonImageDisabled from "../../../assets/continue-button-disabled.svg";
import tryAgainRed from "../../../assets/try-again-red.svg";
import LoadingLottie from "../../../assets/circle-loading.json";
import "./Signup.css";
import NavbarUnauthorized from "../../Common/Navbar/NavbarUnauthorized";

const DISCLOSURE_API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

function Signup() {
  /* 
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    fullName: "",
  });
  const [clickedContinue, setClickedContinue] = useState<boolean>(false);
  const [signUpSubmissionError, setSignUpSubmissionError] =
    useState<boolean>(false);

  const navigate = useNavigate();

  /* 
  ================================
  UI Functionality
  ================================
  */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const reloadPage = () => {
    window.location.reload();
  };

  /* 
  ================================
  SUBMISSION
  ================================
  */
  const disableSubmission =
    formData.email === "" ||
    !formData.email.includes("@") ||
    formData.email.endsWith("@");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setClickedContinue(true);

    if (formData.password !== formData.confirmPassword) {
      return;
    }

    if (formData.password.length < 6) {
      return;
    }

    const uploadData = new FormData();
    uploadData.append("email", formData.email);
    uploadData.append("password", formData.password);
    uploadData.append("display_name", formData.fullName);

    const requestOptions = {
      method: "POST",
      body: uploadData,
    };
    let response;

    response = await fetch(
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/account/signup`,
      requestOptions
    );
    if (!response.ok) {
      alert("Error signing up: " + response.statusText);
      setSignUpSubmissionError(true);
      return;
    }
    //authenticate new user
    let creds;
    creds = await logInEmailPassword(formData.email, formData.password);
    signUpEmailVerification(creds.user)
      .then(() => {
        console.log("Successfully sent email verification");
        navigate("/verify-email-note");
      })
      .catch((error) => {
        console.error("Error sending email verification link:", error);
        setSignUpSubmissionError(true);
      });
  };

  /* 
  ================================
  RENDERING
  ================================
  */
  const lottieLoading = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <NavbarUnauthorized />
      <h1 className="signupHeader">Sign up</h1>
      {!clickedContinue ? (
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="signup-input-group">
            <label className="label-text" htmlFor="fullname">
              Full Name
            </label>
            <input
              type="text"
              id="fullname"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />

            <label className="label-text" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />

            <label className="label-text" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />

            <label className="label-text" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            <p className="password-alert" style={{ color: "red" }}>
              {formData.password !== formData.confirmPassword
                ? "Passwords do not match"
                : ""}
            </p>
            <p className="password-alert" style={{ color: "red" }}>
              {formData.password.length < 6
                ? "Password must be at least 6 characters"
                : ""}
            </p>
          </div>

          <div className="signup-account-action-group">
            <span className="signup-account-action-text">
              Already have an account?
            </span>
            &nbsp;
            <a
              href={`${window.location.origin}/login`}
              className="signup-tologin-link"
            >
              Log in
            </a>
          </div>

          <button
            type="submit"
            disabled={disableSubmission}
            className="signup-submit-button"
            style={
              disableSubmission
                ? { pointerEvents: "none", cursor: "not-allowed" }
                : { cursor: "pointer" }
            }
          >
            <img
              src={disableSubmission ? buttonImageDisabled : buttonImage}
              alt="Continue"
            />
          </button>
        </form>
      ) : signUpSubmissionError ? (
        <div className="signup-error-block">
          <div className="signup-error-block-header">ERROR</div>
          <p>Something went wrong!</p>
          <div
            className="signup-error-block-button-wrapper"
            onClick={reloadPage}
          >
            <img
              className="signup-error-block-button"
              src={tryAgainRed}
              alt="Try again."
            ></img>
          </div>
        </div>
      ) : (
        <Lottie options={lottieLoading} height={200} width={200} />
      )}
    </div>
  );
}

export default Signup;
