// File: src/components/Common/DashboardSideNav.tsx

import React from "react";
import "./DashboardSideNav.css"; // Ensure you create this CSS file

const DashboardSideNav: React.FC = () => {
  return (
    <div className="DashboardSideNav">
      <h2>Disclosure Analyses</h2>
      <hr />
      <nav>
        <ul className="DashboardSideNavList">
          <li>
            <a href="/account">Account</a>
          </li>
          <li>
            <a href="/subscription">Subscription</a>
          </li>
          <li>
            <a href="/logout">Logout</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default DashboardSideNav;
