import { ClassifiedFile } from "../ClassifiedFile";
import { SeverityLevel } from "../Elements";
import { Reference } from "../Reference/Reference";
import { AnalysisModule } from "./AnalysisModule";

export enum CustomComponentAnalysisStatus {
  UNINITIATED = "UNINITIATED",
  ANALYZING = "ANALYZING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}

export interface CustomComponentAnalysisModule extends AnalysisModule {
  id: string;
  name: string;
  type?: string;
  prompt: string | null;
  status: CustomComponentAnalysisStatus;
  analysis: { [key: string]: any };
  associated_files: ClassifiedFile[];
}


export interface CustomComponentAnalysisItem {
  description: string;
  severity: SeverityLevel;
  reference: Reference;
}
export interface CustomComponentAnalysis {
  items: CustomComponentAnalysisItem[];
}