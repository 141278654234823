/* React */
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';

/* UI Components */
import Navbar from "../../Common/Navbar";
import Footer from "../../Common/Footer/Footer";
import MobileAgentReviews from "./MobileAgentReviews/MobileAgentReviews";
import { DemoDisclosureViewer } from "./DemoDisclosureViewer/DemoDisclosureViewer";

/* Styling */
import "./Landing.css";
import "./MobileAgentReviews/MobileAgentReviews.css";
import CompassLogo from "../../../assets/compass.svg";
import ColdwellLogo from "../../../assets/coldwell.svg";
import KWLogo from "../../../assets/kw.svg";
import InteroLogo from "../../../assets/intero.svg";
import SerenoLogo from "../../../assets/sereno.svg";
import crownIcon from "../../../assets/crown.svg";
import lightningboltIcon from "../../../assets/lightningbolt.svg";
import mountainsIcon from "../../../assets/mountains.svg";
import planetIcon from "../../../assets/planet.svg";
import checkIcon from "../../../assets/check-icon.svg";
import whiteCheckIcon from "../../../assets/white-check-icon.svg";
import leftArrowIcon from "../../../assets/left-arrow.svg";
import rightArrowIcon from "../../../assets/right-arrow.svg";
import plusIcon from "../../../assets/plus-icon.svg";
import agentProfile from "../../../assets/testimony-photo.svg";
import HomeGraphic from "../../../assets/home-graphic-landing.svg";
import LandingHeader from "../../../assets/landing-header.svg";
import chatFeature from "../../../assets/chat-feature.svg";
import customAnalyszerFeature from "../../../assets/custom-analyzer-feature.svg"
import shareFeature from "../../../assets/share-feature.svg"
import xIcon from "../../../assets/X-chat.svg"

/* Document Photos */
import SPQDocumentPhoto from "./documentPhotos/SPQ.png";
import TDSDocumentPhoto from "./documentPhotos/TDS.png";
import ESDDocumentPhoto from "./documentPhotos/ESD.png";
import GeneralInspectionDocumentPhoto from "./documentPhotos/HomeInspection.png";
import PestReportDocumentPhoto from "./documentPhotos/TermiteInspection.png";
import RoofInspectionDocumentPhoto from "./documentPhotos/RoofInspection.png";
import PoolInspectionDocumentPhoto from "./documentPhotos/PoolInspection.png";
import HOAFinancialsDocumentPhoto from "./documentPhotos/HOAFinancials.png";
import HOACCRSDocumentPhoto from "./documentPhotos/HOACCRS.png";
import NHDDocumentPhoto from "./documentPhotos/NHD.png";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Landing = () => {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState<number>(0);
  const [showDemo, setShowDemoInternal] = useState(false);
  const [isPopUpExpanded, setIsPopUpExpanded] = useState(false);

  const setShowDemo = (toggle: boolean) => {
    // Start the scroll to top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const scrollDuration = 400;

    setTimeout(() => {
      setShowDemoInternal(toggle);
    }, scrollDuration);
  };

  useEffect(() => {
    const marqueeContent = document.querySelector(
      ".landing-page-marquee-content"
    );
    const marqueeWrapper = document.querySelector(
      ".landing-page-marquee-wrapper"
    );

    if (marqueeContent && marqueeWrapper) {
      // Check for null
      // Clone the marquee content to fill the space
      for (let i = 0; i < 2; i++) {
        // Clone twice for continuous effect
        const clone = marqueeContent.cloneNode(true);
        marqueeWrapper.appendChild(clone);
      }
    }
  }, []);

  return (
    <div className="landing">
      <Navbar />
      <div className="landing-page-body">
        <img
          src={HomeGraphic}
          alt="Home Graphic"
          className="landing-home-graphic"
        />

        {/* HEADER & DEMO */}
        <div className="landing-top-content">
          <div className="landing-top-content-left">
            <div className="landing-top-content-left-header">
              <p className="landing-exclusive-banner">
                <strong>EXCLUSIVE</strong> TO{" "}
                <strong>SILICON VALLEY HOMES</strong> AT THIS TIME
              </p>
              <img
                src={LandingHeader}
                alt="Landing Header"
                className="landing-top-content-left-header-image"
              />
            </div>
            <div className="landing-top-content-left-paragraph">
              to know about a home's disclosure package
              <br />
              <span className="landing-top-content-left-paragraph-rainbow">
                in 1 minute
              </span>
              .
            </div>
            <div className="landing-top-content-left-buttons">
              <div
                className="landing-top-content-left-demo-button"
                onClick={() => setShowDemo(true)}
              >
                Try Free Demo
              </div>
              <div className="landing-top-content-left-trial-button">
                Start Free Trial
              </div>
            </div>
          </div>
          {!false && (
            <div className="landing-top-content-right">
              <DemoDisclosureViewer
                showDemo={showDemo}
                setShowDemo={setShowDemo}
              />
            </div>
          )}
        </div>

        {/* TRUSTED AGENTS */}
        <div className="landing-agent-trust-banner">
          TRUSTED BY TOP AGENTS AT
        </div>
        <div className="landing-brokerage-logos-container">
          <img
            src={CompassLogo}
            alt="Agent Logo 1"
            className="landing-brokerage-logo compass"
          />
          <img
            src={ColdwellLogo}
            alt="Agent Logo 2"
            className="landing-brokerage-logo coldwell"
          />
          <img
            src={KWLogo}
            alt="Agent Logo 3"
            className="landing-brokerage-logo kw"
          />
          <img
            src={InteroLogo}
            alt="Agent Logo 4"
            className="landing-brokerage-logo intero"
          />
          <img
            src={SerenoLogo}
            alt="Agent Logo 5"
            className="landing-brokerage-logo sereno"
          />
        </div>

        {/* SPEED & ACCURACY */}
        <div className="landing-section-banner">Lightning Fast & Accuracte</div>
        <div className="landing-section-banner-subtext">
          Our proprietary analysis has been trained with 1000+ disclosure
          packages making it lightning-fast with unmatched precision
        </div>
        <div className="landing-benefits-container">
          <div className="landing-benefit">
            <img
              src={crownIcon}
              alt="Crown Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">Over $2 Billion</div>
            <div className="landing-benefit-paragraph">
              Curated with over $2B worth of real estate transaction experience.
            </div>
          </div>
          <div className="landing-benefit">
            <img
              src={lightningboltIcon}
              alt="Lightningbolt Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">
              30X <br />
              Faster
            </div>
            <div className="landing-benefit-paragraph">
              Same results the best of agents would generate, only instant and
              efficient.
            </div>
          </div>
          <div className="landing-benefit">
            <img
              src={mountainsIcon}
              alt="Mountains Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">
              Any <br /> Home
            </div>
            <div className="landing-benefit-paragraph">
              All residential property types with CAR® seller disclosure forms.
            </div>
          </div>
          <div className="landing-benefit">
            <img
              src={planetIcon}
              alt="Planet Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">No Human Error</div>
            <div className="landing-benefit-paragraph">
              Eliminate any uncertainties and human margin of error.
            </div>
          </div>

          {/* Duplicated cards for smooth mobile slideshow */}
          <div className="landing-benefit mobile-slideshow-copy">
            <img
              src={crownIcon}
              alt="Crown Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">Over $2 Billion</div>
            <div className="landing-benefit-paragraph">
              Curated with over $2B worth of real estate transaction experience.
            </div>
          </div>
          <div className="landing-benefit mobile-slideshow-copy">
            <img
              src={lightningboltIcon}
              alt="Lightningbolt Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">
              30X <br />
              Faster
            </div>
            <div className="landing-benefit-paragraph">
              Same results the best of agents would generate, only instant and
              efficient.
            </div>
          </div>
          <div className="landing-benefit mobile-slideshow-copy">
            <img
              src={mountainsIcon}
              alt="Mountains Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">
              Any <br /> Home
            </div>
            <div className="landing-benefit-paragraph">
              All residential property types with CAR® seller disclosure forms.
            </div>
          </div>
          <div className="landing-benefit mobile-slideshow-copy">
            <img
              src={planetIcon}
              alt="Planet Icon"
              className="landing-benefit-icon"
            />
            <div className="landing-benefit-title">No Human Error</div>
            <div className="landing-benefit-paragraph">
              Eliminate any uncertainties and human margin of error.
            </div>
          </div>
        </div>

        {/* FEATURES */}
        <div className="landing-features-exhibit">
          <div className="landing-features-exhibit-title">
            An end-to-end solution
          </div>
          <div className="landing-features-exhibit-subtitle">
            Chat with and verify details of a document, analyze every one-off
            scenarios, and share the analysis with important decision makers.
          </div>
        </div>
        <div className="landing-features-exhibit-container">
          <FeatureExhibitItem
            title="Real Time Answers"
            imageSrc={chatFeature}
            description={`Agnsy was developed by dense real-world transaction experience, and we understand that no property or client is like the next. So we crafted a hyper responsive AI chat model to handle any question like an expert and in real time. \n\nStart with one of our preselected prompts - which are unique to every document - or ask away as you wish!`}
          />
          <FeatureExhibitItem
            title="Custome Analyzer"
            imageSrc={customAnalyszerFeature}
            description={`Our custom analyzer allows for even the most unique documents to be reviewed making for a comprehensive report every time. \n\nCompatible with any document. Briefly tell us what the document is about and we will take care of the rest.`}
          />
          <FeatureExhibitItem
            title="Share"
            imageSrc={shareFeature}
            description={`\nKeep all important decision makers in the loop with our analysis share feature.`}
          />
        </div>

        {/* FORMS WE ANALYZE */}
        <div className="landing-documents-section">
          <div className="landing-section-banner">Documents We Analyze</div>
          <div className="landing-section-banner-subtext">
            (everything important)
          </div>

          {/* TABS */}
          <div className="landing-document-type-tabs-container">
            {documentsWeAnalyze.map((section, index) => (
              <div
                key={index}
                className={`landing-document-type-tab ${
                  selectedSectionIndex === index ? "active" : ""
                }`}
                onClick={() => setSelectedSectionIndex(index)}
              >
                {section.name}
              </div>
            ))}
          </div>

          {/* Section content */}

          {documentsWeAnalyze[selectedSectionIndex].desktopViewer}
        </div>

        {/* TESTIMONIALS */}
        <div className="landing-section-banner">Don't take our word for it</div>
        <div className="landing-section-banner-subtext">
          Hear from some of our recognized agents
        </div>

        {/* Desktop testimonials */}
        <div className="landing-agent-reviews">
          <div className="landing-agent-reviews-carousel">
            <div className="landing-agent-reviews-carousel-content">
              {[...Array(5), ...Array(5)].map((_, index) => (
                <div key={index} className="landing-agent-review-card">
                  <div className="landing-agent-review-card-message">
                    "We've been using Agnsy to kick start every new project and
                    can't imagine working without it."
                  </div>
                  <div className="landing-agent-review-card-profile">
                    <img
                      src={agentProfile}
                      alt="Agent Profile"
                      className="landing-agent-review-card-profile-image"
                    />
                    <div className="landing-agent-review-card-profile-info">
                      <span className="landing-agent-review-card-profile-info-name">
                        JPMoridi
                      </span>
                      <span className="landing-agent-review-card-profile-info-title">
                        Partner Agent, Keller Williams Luxury Estates
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile testimonials */}
        <MobileAgentReviews />

        {/* SUBSCRIPTION PLANS */}
        <div className="landing-plans" id="landing-plans-id">
          <div className="landing-section-banner">Plans</div>
          <div className="landing-section-banner-subtext">
            Simple, transparent pricing that grows with you. Try free for 7
            days.
          </div>
          <div className="landing-plans-options">
            <div className="landing-plans-option-gradient-edge-left" />
            {/* BASIC */}
            <div className="landing-plans-option">
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header">BASIC</div>
                <div className="landing-plans-option-header-subtext">
                  Homebuyer's and beginner agents
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header-price">
                  $49
                  <div className="landing-plans-option-header-billing">
                    Billed Monthly
                  </div>
                </div>
                <div className="landing-plans-start-button">
                  Start 7 Days Free
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />
              <div className="landing-plans-option-benefits-list">
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>5 disclosure packages analyses per month</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Analysis on all relevant documents</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Share analysis with client feature</span>
                </div>
              </div>
            </div>
            {/* PRO */}
            <div className="landing-plans-option pro">
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header pro">
                  PRO
                  <div className="landing-plans-option-header-popular-tag">
                    Popular
                  </div>
                </div>
                <div className="landing-plans-option-header-subtext pro">
                  The active agent's preference.
                </div>
              </div>
              <div className="landing-plans-option-internal-separator pro" />
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header-price pro">
                  $99
                  <div className="landing-plans-option-header-billing">
                    Billed Monthly
                  </div>
                </div>
                <div className="landing-plans-start-button pro">
                  Start 7 Days Free
                </div>
              </div>
              <div className="landing-plans-option-internal-separator pro" />
              <div className="landing-plans-option-benefits-list pro">
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>20 disclosure packages analyses per month</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Analysis on all relevant documents</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Share analysis with client feature</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Agnsy Document Chat</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Custom Document Analyzer</span>
                </div>
                <div className="landing-plans-option-benefits-list-item pro">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Early access to new features</span>
                </div>
              </div>
            </div>
            {/* VOLUME */}
            <div className="landing-plans-option">
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header">VOLUME</div>
                <div className="landing-plans-option-header-subtext">
                  Top performers and teams.
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />
              <div className="landing-plans-option-row-container">
                <div className="landing-plans-option-header-price">
                  $199
                  <div className="landing-plans-option-header-billing">
                    Billed Monthly
                  </div>
                </div>
                <div className="landing-plans-start-button">
                  Start 7 Days Free
                </div>
              </div>
              <div className="landing-plans-option-internal-separator" />

              <div className="landing-plans-option-benefits-list">
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>50 disclosure packages analyses per month</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Analysis on all relevant documents</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Share analysis with client feature</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Agnsy Document Chat</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Custom Document Analyzer</span>
                </div>
                <div className="landing-plans-option-benefits-list-item">
                  <img
                    src={whiteCheckIcon}
                    alt="Check Icon"
                    className="landing-plans-option-benefits-list-item-check "
                  />
                  <span>Early access to new features</span>
                </div>
              </div>
            </div>
            <div className="landing-plans-option-gradient-edge-right" />
          </div>
        </div>

        {/* Marquee */}
        <div className="landing-page-marquee">
          <div className="landing-page-marquee-wrapper">
            <div className="landing-page-marquee-content">
              {[...Array(5), ...Array(5)].map((_, index) => (
                <div className="marque-item">
                  <li>
                    <strong>30 day money back guarantee</strong> from first
                    billing cycle
                  </li>
                  <li className="seperator">|</li>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <p className="landing-free-trial-message">
        Struggling to select a plan? <strong>Start free trial</strong> now and
        decide later
      </p>
      <Footer />
    </div>
  );
};

type DocumentProperties = {
  header: string;
  image: string;
  items: string[];
};

type DocumentSectionDisplayProps = {
  documents: DocumentProperties[];
};

function DocumentSectionDisplay({
  documents,
}: DocumentSectionDisplayProps): JSX.Element {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(2);
  const [numDocuments, setNumDocuments] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
    setNumDocuments(documents.length);
  }, [documents]);

  useEffect(() => {
    const updateItemsToShow = () => {
      if (window.innerWidth <= 450) {
        setItemsToShow(1);
      } else {
        setItemsToShow(2);
      }
    };

    updateItemsToShow();
    window.addEventListener("resize", updateItemsToShow);

    return () => {
      window.removeEventListener("resize", updateItemsToShow);
    };
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + itemsToShow;
      return newIndex >= numDocuments ? 0 : newIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - itemsToShow;
      return newIndex < 0 ? numDocuments - itemsToShow : newIndex;
    });
  };

  return (
    <div className="landing-document-type-carousel-section">
      <div className="landing-document-type-carousel-section-content">
        {documents
          .slice(currentIndex, currentIndex + itemsToShow)
          .map((section, index) => (
            <div
              key={index}
              className="landing-document-type-carousel-section-item"
            >
              <div className="landing-document-type-carousel-section-item-header">
                {section.header}
              </div>
              <img
                src={section.image}
                alt="icon"
                className="landing-document-type-carousel-section-item-picture"
              />
              <div className="landing-document-type-carousel-section-internal-divider" />
              <div className="landing-document-type-carousel-section-bulleted-list-wrapper">
                <div className="landing-document-type-carousel-section-bulleted-list">
                  {section.items.map((item, idx) => (
                    <div
                      key={idx}
                      className="landing-document-type-carousel-section-bulleted-list-item"
                    >
                      <img
                        src={checkIcon}
                        alt="Check Icon"
                        className="landing-check-icon circled"
                      />
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="landing-document-type-carousel-buttons">
        <button
          className="landing-document-type-carousel-button"
          onClick={handlePrevious}
          disabled={currentIndex === 0}
          style={{ marginRight: "5px" }}
        >
          <img src={leftArrowIcon} alt="Scroll Left" />
        </button>
        <button
          className="landing-document-type-carousel-button"
          onClick={handleNext}
          disabled={currentIndex >= numDocuments - itemsToShow}
          style={{ marginLeft: "5px" }}
        >
          <img src={rightArrowIcon} alt="Scroll Right" />
        </button>
      </div>
      <div className="landing-document-type-carousel-mobile-dots-container">
        {documents.map((_, index) => (
          <div
            key={index}
            className={`landing-document-type-carousel-mobile-dot ${
              currentIndex === index ? "active" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
}

interface FeatureExhibitItemProps {
  title: string;
  imageSrc: string;
  description: string;
}

const FeatureExhibitItem: React.FC<FeatureExhibitItemProps> = ({ title, imageSrc, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    document.body.style.overflow = isExpanded ? 'auto' : 'hidden';
  };

  const expandedContent = (
    <div className="landing-features-exhibit-item-overlay">
      <div className="landing-features-exhibit-item-expanded">
        <img 
          src={xIcon}
          alt="Close"
          className="landing-features-exhibit-item-close-icon"
          onClick={handleExpand}
        />
        <div className="landing-features-exhibit-item-expanded-content">
          <img 
            src={imageSrc} 
            alt={`${title} Image`}
            className="landing-features-exhibit-item-expanded-image"
          />
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`landing-features-exhibit-item ${isExpanded ? 'expanded' : ''}`}>
      <div className="landing-features-exhibit-item-content">
        <img 
          src={imageSrc} 
          alt={`${title} Image`}
          className="landing-features-exhibit-item-image"
        />
      </div>
      <span className="landing-features-exhibit-item-label">
        <div>{title}</div>
        <img
          src={plusIcon}
          className="landing-features-exhibit-item-label-expand-button"
          alt="Expand"
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </span>
      {isExpanded && ReactDOM.createPortal(expandedContent, document.body)}
    </div>
  );
};

const documentsWeAnalyze = [
  {
    name: "Seller Documents",
    desktopViewer: (
      <DocumentSectionDisplay
        documents={[
          {
            header: "Seller Property Questionnaire",
            image: SPQDocumentPhoto,
            items: [
              "California homes with the latest Seller Property Questionnaires from CAR®",
              "All concerning items identified and ranked by Agnsy Severity",
            ],
          },
          {
            header: "Transfer Disclosure Statement",
            image: TDSDocumentPhoto,
            items: [
              "California homes with the latest Transfer Disclosure Statements from CAR®",
              "All concerning items identified and ranked by Agnsy Severity",
            ],
          },
          {
            header: "Exempt Seller Disclosure",
            image: ESDDocumentPhoto,
            items: [
              "California homes with the latest Exempt Seller Disclosures from CAR®",
              "All concerning items identified and ranked by Agnsy Severity",
            ],
          },
        ]}
      />
    ),
  },
  {
    name: "Inspection Reports",
    desktopViewer: (
      <DocumentSectionDisplay
        documents={[
          {
            header: "General Inspection Report",
            image: GeneralInspectionDocumentPhoto,
            items: [
              "All concerning items identified, categorized and ranked by Agnsy Severity",
              "Any general inspection company and area",
            ],
          },
          {
            header: "Pest Report",
            image: PestReportDocumentPhoto,
            items: [
              "All concerning items and associated corrective costs identified",
              "Sections 1, 2, and “further inspection” respectively categorized",
              "Any pest inspection company and area",
            ],
          },
          {
            header: "Structural Report",
            image: PestReportDocumentPhoto /* TODO: change */,
            items: [
              "All concerning items ranked by Agnsy severity",
              "Associated corrective costs identified",
              "Any structural inspection company and area",
            ],
          },
          {
            header: "Pool Report",
            image: PoolInspectionDocumentPhoto,
            items: [
              "All concerning items and associated corrective costs identified",
              "Any pool inspection company and area",
            ],
          },
          {
            header: "Roof Inspection",
            image: RoofInspectionDocumentPhoto,
            items: [
              "All concerns with corrective costs and overall condition identified",
              "Any roof inspection company and area",
            ],
          },
        ]}
      />
    ),
  },
  {
    name: "HOA",
    desktopViewer: (
      <DocumentSectionDisplay
        documents={[
          {
            header: "Reserve & financial study",
            image: HOAFinancialsDocumentPhoto,
            items: [
              "Funding percentage identified",
              "Reserve health classified by Agnsy Rating",
              "Any reserve study from any community",
            ],
          },
          {
            header: "CC&Rs",
            image: HOACCRSDocumentPhoto,
            items: [
              "Rental duration, capacity, and other requirements identified",
              "Any CC&R document from any community",
            ],
          },
        ]}
      />
    ),
  },
  {
    name: "Other",
    desktopViewer: (
      <DocumentSectionDisplay
        documents={[
          {
            header: "Natural Hazard Disclosure",
            image: NHDDocumentPhoto,
            items: [
              "All environmental concerns identified and ranked by Agsny Severity",
              "Any natural hazard or JCP report and area",
            ],
          },
          {
            header: "Preliminary Title Report",
            image: NHDDocumentPhoto /* TODO: change */,
            items: [
              "Identity cloudly title issues from seller disputes, liens, unpaid taxes, and mortgages",
              "Any preliminary title report and area",
            ],
          },
        ]}
      />
    ),
  },
];
