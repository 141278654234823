import React, { useState } from "react";
import "./Account.css";
import { Auth, deleteWithAuth } from "../../../firebase/authentication/auth";
import withAuthorization from "../../../firebase/authentication/AuthorizedComponent";
import { ForgotPasswordEmailVerification } from "../../../firebase/authentication/functions";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Common/Navbar";

const auth = Auth;

interface CustomerPortalSessionResponse {
  url?: string;
}

const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const API_PREFIX =
  HTTP_PROTOCAL + "://" + process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;

async function deleteAccount() {
  const user = auth.currentUser;
  if (user) {
    let result = await deleteWithAuth(`${API_PREFIX}/api/account/delete`);
    console.log("DELETE RESULT: ", result);
  }
}

function Account() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleManageBilling = async () => {
    const user = auth.currentUser;

    console.log(user);

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("User is signed in");
        const token = await user.getIdToken();
        return fetch(
          `${HTTP_PROTOCAL}://${process.env.REACT_APP_DISCLOSURE_ANALYSIS_API}/api/account/subscription/create-customer-portal-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response: Response) => response.json())
          .then((data: CustomerPortalSessionResponse) => {
            if (data.url) {
              console.log("Redirecting to customer portal");
              window.location.href = data.url; // Use window.location.href for redirection
            } else {
              throw new Error("No URL returned from the server");
            }
          })
          .catch((error: Error) => {
            console.error("Error:", error.message);
          });
      } else {
        console.log("No user is signed in");
      }
    });
  };

  const handleConfirmDelete = async () => {
    const user = auth.currentUser;
    if (user) {
      await deleteAccount();
      //redirect to signup page
      navigate("/signup");
    }
    setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const user = auth.currentUser;
    if (user && user.email) {
      ForgotPasswordEmailVerification(user.email)
        .then(() => {
          console.log("Successfully sent email verification");
          navigate("/verify-email-note");
        })
        .catch((error) => {
          console.error("Error sending email verification link:", error);
          // Optionally update UI to show error
        });
    }
  };

  return (
    <div className="account-page">
      <Navbar />
      Account Page
      <br />
      {/* Replace the Reset Password link with a button to trigger email verification */}
      <button className="account-page-button" onClick={handleSubmit}>
        Reset Password
      </button>
      <br />
      <button className="account-page-button" onClick={handleManageBilling}>
        Manage Billing
      </button>
      <br />
      <button onClick={handleDeleteClick} className="account-page-button">
        Delete Account
      </button>
      {showConfirmation && (
        <div className="confirmation-dialog">
          Are you sure?
          <button
            className="account-delete-confirm-button"
            onClick={handleConfirmDelete}
          >
            Yes
          </button>
          <button
            className="account-delete-confirm-button"
            onClick={handleCancelDelete}
          >
            No
          </button>
        </div>
      )}
    </div>
  );
}

export default withAuthorization(Account);
