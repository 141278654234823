import { FirestoreChatRecord } from "../../Chat/Chat";
import { ClassifiedFile } from "../../ClassifiedFile";
import { AnalysisModule } from "../AnalysisModule";
import ComponentAnalysisType, { ComponentCategories } from "./Types";

export enum ComponentAnalysisStatus {
  UNINITIATED = "UNINITIATED",
  FILECLASSIFYING = "FILECLASSIFYING",
  FILES_NOT_DETECTED = "FILES_NOT_DETECTED",
  ANALYZING = "ANALYZING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}
export type ComponentAnalysisGeneric<T = { [key: string]: any }> = T;

export interface ComponentAnalysisModule<T = { [key: string]: any }> extends AnalysisModule {
  id: string;
  type: ComponentAnalysisType;
  status: ComponentAnalysisStatus;
  analysis: T;
  associated_files: ClassifiedFile[];
  associated_chat: FirestoreChatRecord;
}

export const sortComponentAnalysisByCategory = (
  analyses: ComponentAnalysisModule[]
): ComponentAnalysisModule[] => {
  const sortedAnalyses: ComponentAnalysisModule[] = [];

  for (const category in ComponentCategories) {
    for (const analysis of analyses) {
      if (ComponentCategories[category].includes(analysis.type)) {
        sortedAnalyses.push(analysis);
      }
    }
  }

  return sortedAnalyses;
};
