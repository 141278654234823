import React from "react";
import Navbar from "../../Common/Navbar";
import Footer from "../../Common/Footer/Footer";
import { privacyPolicyItems } from "./LegalItems";
import "./PrivacyPolicy.css";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-page">
      <Navbar />
      <div className="privacy-policy-page-body">
        
        <div className="privacy-policy-header">
            <div className="privacy-policy-title">Privacy Policy</div>
            <div className="privacy-policy-subtitle"><strong>Effective date:</strong> 19th of August, 2024</div>
        </div>
        
        {privacyPolicyItems.map((item, index) => (
          <div key={index} className="privacy-policy-item">
            <div className="privacy-policy-item-title">{item.title}</div>
            <div className="privacy-policy-item-text">{item.text}</div>
          </div>
        ))}
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;