/**
 * Elements
 */

export enum Checkbox {
  CHECKED = 1,
  UNCHECKED = 0,
}

export enum SeverityLevel {
  MINOR = "MINOR",
  MODERATE = "MODERATE",
  MAJOR = "MAJOR",
  CRITICAL = "CRITICAL",
  NOTAPPLICABLE = "NOTAPPLICABLE",
  NONE = "NONE"
}

export const SeverityLevelOrder = {
  CRITICAL: 1,
  MAJOR: 2,
  MODERATE: 3,
  MINOR: 4,
  NOTAPPLICABLE: 5,
  NONE: 6
};

export enum AnalysisAttributeCategory {
  REDFLAG = "REDFLAG",
  INFO = "INFO",
  DUMP = "DUMP",
}

export enum Condition {
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  FAIR = "FAIR",
  POOR = "POOR",
  UNDETERMINABLE = "UNDETERMINABLE",
}
export function isConditionValue(conditionString: string): boolean {
  return Object.values(Condition).includes(conditionString as Condition);
}