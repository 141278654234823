import "./BetaNote.css";
import Navbar from "../../Common/Navbar";
import buttonImage from "../../../assets/confirm-button.svg";

// @ts-ignore
import { useNavigate } from "react-router-dom";

function BetaNote() {
  const navigate = useNavigate();

  const continueButton = () => {
    navigate("/disclosure");
  };

  return (
    <div className="beta-note">
      <Navbar />
      <div className="beta-note-header">Beta Mode</div>
      <div className="beta-note-text">
        Please understand we are still in the process of perfecting our
        algorithm and right now are only available to the highest level of
        agents in the Silicon Valley as a complimentary, experimental tool.
        <br />
        <br />
        Independent research and further due diligence is highly recommended.
      </div>
      <button
        type="submit"
        onClick={continueButton}
        className="beta-note-submit-button"
      >
        <img src={buttonImage} alt="Continue" />
      </button>
    </div>
  );
}

export default BetaNote;
