import React, { useEffect } from "react";
import Navbar from "../components/Common/Navbar";
import { Auth } from "../firebase/authentication/auth";
import { User } from "firebase/auth";
import withAuthorization from "../firebase/authentication/AuthorizedComponent";
import { useNavigate } from "react-router-dom";

const BASIC_PRICE = process.env.REACT_BASIC_SUBSCRIPTION;
const API_BASE_URL = process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
const HTTP_PROTOCAL =
  process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";

const CheckoutForm = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("User is logged in");
        redirectPayment(user);
      } else {
        console.log("No user logged in");
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]); // Added an empty dependency array to the useEffect hook

  const session_url = `${HTTP_PROTOCAL}://${API_BASE_URL}/api/account/subscription/create-checkout-session`;

  const redirectPayment = async (user: User) => {
    try {
      const current_token = await user.getIdToken();
      const response = await fetch(session_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${current_token}`,
        },
        // body: JSON.stringify({ priceID: BASIC_PRICE }),
      });
      const data = await response.json();
      window.location.replace(data.url);
    } catch (error) {
      console.error("Error fetching disclosures:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <form action={session_url} method="POST">
        <button type="submit">Go to Checkout</button>
      </form>
    </div>
  );
};

export default withAuthorization(CheckoutForm);
