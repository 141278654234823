import { StartAnalysisResponse } from "../../../model/DisclosureAnalysis/Endpoints";
import { DisclosurePackageID } from "../../../model/DisclosureAnalysis/DisclosurePackage";

import { getCurrentUser } from "../../../firebase/authentication/auth";

export async function startAnalysis(
  disclosureZip: File,
  onProgress: (progressEvent: ProgressEvent) => void
): Promise<DisclosurePackageID> {
  return new Promise(async (resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    formData.append("zip", disclosureZip);

    const HTTP_PROTOCAL =
      process.env.REACT_APP_SSL_ENABLED === "true" ? "https" : "http";
    const DISCLOSURE_API_BASE_URL =
      process.env.REACT_APP_DISCLOSURE_ANALYSIS_API;
    const currentUser = await getCurrentUser();
    const token = await currentUser?.getIdToken();

    xhr.open(
      "POST",
      `${HTTP_PROTOCAL}://${DISCLOSURE_API_BASE_URL}/api/disclosure/analysis/start`
    );
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);

    // Event listener to track upload progress
    xhr.upload.addEventListener("progress", onProgress);

    // Event listener for when the request completes
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          const response = JSON.parse(xhr.responseText);
          resolve(response.disclosure_id);
        } else {
          reject(new Error("Network response was not ok."));
        }
      }
    };

    xhr.onerror = () => reject(new Error("Network request failed."));
    xhr.send(formData);
  });
}
