import { AnalysisAttributeCategory, SeverityLevel } from "../../../Elements";
import { Reference } from "../../../Reference/Reference";

export enum TransferDisclosureAnalysisType {
  CAR_TDS_0623 = "CAR_TDS_0623",
  PRDS_TDS_0520 = "PRDS_TDS_0520",
  TRANSFERDISCLOSUREDEFAULT = "TRANSFERDISCLOSUREDEFAULT",
  ADDENDUM = "ADDENDUM",
}

export interface TransferDisclosureAnalysisAttribute {
  description: string;
  severity: SeverityLevel;
  category: AnalysisAttributeCategory;
  reference: Reference;
}

export interface TransferDisclosureAnalysis {
  items: TransferDisclosureAnalysisAttribute[];
}
