import React, { useEffect, useState } from "react";
import NavbarAuthorized from "./NavbarAuthorized";
import NavbarUnauthorized from "./NavbarUnauthorized";
import { Auth } from "../../../firebase/authentication/auth";

export default function Navbar() {
  // Assuming you store some flag or token in localStorage when authenticated
  const isLikelyAuthenticated =
    localStorage.getItem("isAuthenticated") === "true";

  const [isAuthenticated, setIsAuthenticated] = useState(isLikelyAuthenticated);

  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged((authUser) => {
      const userAuthenticated = !!authUser;
      setIsAuthenticated(userAuthenticated);
      localStorage.setItem("isAuthenticated", userAuthenticated.toString());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return isAuthenticated ? <NavbarAuthorized /> : <NavbarUnauthorized />;
}
