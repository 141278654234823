import React, { useEffect } from "react";
import { Auth } from "./auth";
import { useNavigate } from "react-router-dom";

const AuthorizedComponent = (Component: React.ComponentType) => {
  const AuthorizedContent: React.FC = () => {
    return <Component />;
  };

  const WithAuthorization: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const unsubscribe = Auth.onAuthStateChanged((authUser) => {
        if (!authUser) {
          navigate("/login");
        }
      });
      unsubscribe();
    }, [navigate]);

    return <AuthorizedContent />;
  };

  return WithAuthorization;
};

export default AuthorizedComponent;
