import React, { useEffect, useState } from "react";
import Navbar from "../../Common/Navbar";
import Lottie from "react-lottie";

// @ts-ignore
import { useNavigate } from "react-router-dom";
import {
  logInEmailPassword,
  isValidRegistrationLink,
  matchingEmailAndLink,
  updatePassword,
  deleteUser,
} from "../../../firebase/authentication/functions";
import { addUserFirestore } from "../../../firebase/firestore/functions";

import buttonImage from "../../../assets/continue-button.svg";
import buttomImageDisabled from "../../../assets/continue-button-disabled.svg";
import tryAgainRed from "../../../assets/try-again-red.svg";
import LoadingLottie from "../../../assets/circle-loading.json";
import {
  query,
  collection,
  where,
  getDocs,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firestore/firestore";
import "./Register.css";
import NavbarUnauthorized from "../../Common/Navbar/NavbarUnauthorized";

/* 
================================
API
================================
*/
type AuthorizedRegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordMatch: string;
  termsOfService: boolean;
};

/* 
================================
COMPONENTS
================================
*/
function UnAuthorizedRegister() {
  /* 
  ================================
  RENDERING
  ================================
  */
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get("mode");
    const oobCode = queryParams.get("oobCode");
    const apiKey = queryParams.get("apiKey");

    if (mode === "resetPassword") {
      navigate(
        `/update-password?apiKey=${apiKey}&mode=${mode}&oobCode=${oobCode}`
      );
    }
  }, [navigate]);
  return (
    <div>
      <Navbar />
      <div className="unauthorized-register">
        <div className="unauthorized-register-header">Register</div>
        <div className="unauthorized-register-message">
          <span>Uh oh!</span> &nbsp;You are using an invalid registration link.
          <br />
          Follow the steps below to register:
        </div>
        <div className="unauthorized-register-steps-block">
          <div className="unauthorized-register-step">
            <div className="unauthorized-register-step-number">1</div>
            <div className="unauthorized-register-step-message">
              Enter email on{" "}
              <a href={`${window.location.origin}/signup`}>sign up</a> page to
              receive verification email
            </div>
          </div>
          <div className="unauthorized-register-step">
            <div className="unauthorized-register-step-number">2</div>
            <div className="unauthorized-register-step-message">
              Click on registration link in your verification email
            </div>
          </div>
          <div className="unauthorized-register-step">
            <div className="unauthorized-register-step-number">3</div>
            <div className="unauthorized-register-step-message">
              Complete registration
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AuthorziedRegister() {
  /*
  ================================
  VARS
  ================================
  */
  const [formData, setFormData] = useState<AuthorizedRegisterFormData>({
    firstName: "",
    lastName: "",
    email:
      window.localStorage.getItem("emailForSignUp") !== null
        ? String(window.localStorage.getItem("emailForSignUp"))
        : "",
    phoneNumber: "",
    password: "",
    passwordMatch: "",
    termsOfService: false,
  });
  const [clickedContinue, setClickedContinue] = useState<boolean>(false);
  const [registrationErrorMsg, setRegistrationErrorMsg] = useState<string>("");

  const navigate = useNavigate();

  /* 
  ================================
  UI FUNCTIONALITY
  ================================
  */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: !formData.termsOfService,
    }));
  };
  const reloadPage = () => {
    window.location.reload();
  };

  /*
  ================================
  REGISTRATION SUBMISSION
  ================================
  */
  const disableContinue =
    formData.firstName === "" ||
    formData.lastName === "" ||
    formData.email === "" ||
    formData.password.length <= 6 ||
    formData.password !== formData.passwordMatch ||
    formData.phoneNumber.length !== 10 ||
    !/^[0-9]+$/.test(formData.phoneNumber) ||
    !formData.termsOfService;

  const formErrorMessages = [
    {
      id: 1,
      message: "Must have a first name",
      condition: formData.firstName === "",
    },
    {
      id: 2,
      message: "Must have a last name",
      condition: formData.lastName === "",
    },
    { id: 3, message: "Must have an email", condition: formData.email === "" },
    {
      id: 4,
      message: "Password must be more than 6 characters",
      condition: formData.password.length <= 6,
    },
    {
      id: 5,
      message: "Passwords must match",
      condition: formData.password !== formData.passwordMatch,
    },
    {
      id: 6,
      message: `Phone must have 10 digits (ex: "1234567890")`,
      condition: formData.phoneNumber.length !== 10,
    },
    {
      id: 7,
      message: "Phone must only include numbers",
      condition: !/^[0-9]+$/.test(formData.phoneNumber),
    },
    {
      id: 8,
      message: "Must accept Terms of Service",
      condition: !formData.termsOfService,
    },
  ];

  // Filter the list based on a custom condition
  const filteredFormErrorMessages = formErrorMessages.filter(
    (item) => item.condition
  );

  const checkPhoneNumberExists = async (
    phoneNumber: string
  ): Promise<boolean> => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot: QuerySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };
  const checkEmailExists = async (email: string): Promise<boolean> => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot: QuerySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleRegistrationSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setClickedContinue(true);
    setRegistrationErrorMsg("");

    // Step 1: Ensure email and verification link match, create account
    matchingEmailAndLink(formData.email, window.location.href)
      .then((userCredential) => {
        setRegistrationErrorMsg("");

        checkPhoneNumberExists(formData.phoneNumber).then(
          (phoneNumberExists) => {
            if (phoneNumberExists) {
              setRegistrationErrorMsg(
                "An account is already associated with this phone number."
              );
              return;
            }
            checkEmailExists(formData.email)
              .then((emailExists) => {
                if (emailExists) {
                  setRegistrationErrorMsg(
                    "An account is already associated with this email."
                  );
                  return;
                }

                // matchingEmailAndLink creates a new user automatically if not already exisiting
                // Update password/phone
                updatePassword(userCredential.user, formData.password)
                  .then(() => {
                    addUserFirestore(
                      formData.firstName,
                      formData.lastName,
                      formData.phoneNumber,
                      formData.email
                    ).then(() => {
                      logInEmailPassword(
                        formData.email,
                        formData.password
                      ).then(() => {
                        navigate("/checkout");
                      });
                    });
                  })
                  .catch((error) => {
                    deleteUser(userCredential.user)
                      .then(() => {
                        setRegistrationErrorMsg(
                          "Error creating account. Try again soon."
                        );
                      })
                      .catch(() => {
                        setRegistrationErrorMsg(
                          "Error creating account. Try again soon."
                        );
                      });
                  });
              })
              .catch((error) => {
                if (error.code === "auth/invalid-action-code") {
                  setRegistrationErrorMsg(
                    "Verification links are for one-time use. Please request a new verification link."
                  );
                } else {
                  setRegistrationErrorMsg(
                    "Error creating account. Please try again soon."
                  );
                }
              });
          }
        );
      })
      .catch((error) => {
        // Handle any errors that occur
        setRegistrationErrorMsg(
          "An error occurred while checking the phone number." + error
        );
        return;
      });
  };

  /*
  ================================
  RENDERING
  ================================
  */
  const lottieLoading = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <NavbarUnauthorized />
      <h1 className="register-header">Register</h1>
      {!clickedContinue ? (
        <form
          onSubmit={handleRegistrationSubmit}
          className="register-form-wrapper"
        >
          <div className="register-form">
            <div className="register-input-group">
              <label className="register-label-text" htmlFor="firstName">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-input-group">
              <label className="register-label-text" htmlFor="lastName">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-input-group">
              <label className="register-label-text" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-input-group">
              <label className="register-label-text" htmlFor="phoneNumber">
                Phone number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-input-group register-full-width">
              <label className="register-label-text" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-input-group register-full-width">
              <label className="register-label-text" htmlFor="passwordMatch">
                Re-enter Password
              </label>
              <input
                type="password"
                id="passwordMatch"
                name="passwordMatch"
                value={formData.passwordMatch}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            <div className="register-checkbox-terms-button-group">
              <div className="register-checkbox-terms-container">
                <input
                  type="checkbox"
                  id="termsOfService"
                  name="termsOfService"
                  className="register-checkbox"
                  placeholder="off"
                  checked={formData.termsOfService === true}
                  onChange={handleCheckbox}
                />
                <label
                  htmlFor="termsOfService"
                  className="register-terms-of-service"
                >
                  I have read the{" "}
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms of service
                  </a>
                </label>
              </div>
            </div>
          </div>

          {filteredFormErrorMessages.length > 0 ? (
            <ul className="register-form-errors">
              {filteredFormErrorMessages.map((item) => (
                <li key={item.id}>{item.message}</li>
              ))}
            </ul>
          ) : (
            <div />
          )}

          <div className="register-submit-button-wrapper">
            <button
              type="submit"
              className="register-submit-button"
              disabled={disableContinue}
              style={
                disableContinue
                  ? { pointerEvents: "none", cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <img
                src={disableContinue ? buttomImageDisabled : buttonImage}
                alt="Continue"
              />
            </button>
          </div>
        </form>
      ) : registrationErrorMsg === "" ? (
        <Lottie options={lottieLoading} height={200} width={200} />
      ) : (
        <div className="signup-error-block">
          <div className="signup-error-block-header">ERROR</div>
          <p>{registrationErrorMsg}</p>
          <div
            className="signup-error-block-button-wrapper"
            onClick={reloadPage}
          >
            <img
              className="signup-error-block-button"
              src={tryAgainRed}
              alt="Try again."
            ></img>
          </div>
        </div>
      )}
    </div>
  );
}

function Register() {
  const registrationLinkIsValid = isValidRegistrationLink(window.location.href);

  return registrationLinkIsValid ? (
    <AuthorziedRegister />
  ) : (
    <UnAuthorizedRegister />
  );
}

export default Register;
