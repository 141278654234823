import { useNavigate } from "react-router-dom";
import { logoutCurrentUser } from "../../../firebase/authentication/functions";

import "./LogoutButton.css";

export default function LogoutButton() {
  const navigate = useNavigate();

  const handleLogoutButton = () => {
    logoutCurrentUser().then(() => {
      navigate("/");
    });
  };

  return (
    <div className="navbar-dashboard-logout" onClick={handleLogoutButton}>
      Logout
    </div>
  );
}
