interface FAQItem {
    question: string;
    answer: string;
}

export const faq_items: FAQItem[] = [
    {
        question: "What disclosure packages are compatible?",
        answer: "We completely support all residential property types with California Association of Realtors (CAR) seller forms and are geographically unbound with Homeowners Association (HOA) documents, Inspection Reports, Natural Hazard Reports, and Preliminary Title Reports."
    },
    {
        question: "Reliability?",
        answer: "Our platform was developed by a team of four UCLA-educated engineers, leveraging the expertise of nationally ranked Silicon Valley agents with a combined career sales volume exceeding $2 billion. We performed rigorous testing across 1,000+ disclosure packages over a 12-month development period before considering launch. While we strive for 100% accuracy, the nature of artificial intelligence means there is always a small margin of error. We have taken every possible measure to minimize this margin and deliver consistently accurate results. "
    },
    {
        question: "How do I format the disclosure package to ensure every relavant document is detected?",
        answer: "We utilize a sophisticated file classification process to detect and categorize files accurately. Our system has a 98% accuracy rate in correctly associating files. The 2% margin of error is due to instances where listings agents may use unrelated keywords in file names, such as labeling a home inspection report as \"seller disclosures\". As long as the document's file name is relatively clear and relates to the contents of the document, our system can process it correctly. For example: \"pest report\" for a Termite Inspection Report, \"home inspection\" for a General Inspection Report, and \"HOA financials\" for an HOA Reserve Study."
    },
    {
        question: "Can I login from different devices?",
        answer: "Yes! Your account is not limited to a single device. Usage is tacked and limited according to your membership plan."
    },
    {
        question: "Why is my analysis taking so long?",
        answer: "Out output will never take more than 1 minute to process and display. If there are any delays beyond 60 seconds then you are likely on slower wifi, and if that's the case please allow up to a couple minutes."
    },
    {
        question: "7-day money-back guarantee?",
        answer: "Yup! In the unlikely case that you do not absolutely love what we've created, please email membership@agnsy.ai within 7 calendar days of sign up for a full refund ."
    }
]

export const faq_items_mobile: FAQItem[] = [
    {
        question: "What disclosure packages are compatible?",
        answer: "We completely support all residential property types with California Association of Realtors (CAR) seller forms and are geographically unbound with Homeowners Association (HOA) documents, Inspection Reports, Natural Hazard Reports, and Preliminary Title Reports."
    },
    {
        question: "Reliability?",
        answer: "Our platform was developed by a team of four UCLA-educated engineers, leveraging the expertise of nationally ranked Silicon Valley agents with a combined career sales volume exceeding $2 billion. We performed rigorous testing across 1,000+ disclosure packages over a 12-month development period before considering launch. While we strive for 100% accuracy, the nature of artificial intelligence means there is always a small margin of error. We have taken every possible measure to minimize this margin and deliver consistently accurate results. "
    },
    {
        question: "How do I format the disclosure package?",
        answer: "We utilize a sophisticated file classification process to detect and categorize files accurately. Our system has a 98% accuracy rate in correctly associating files. The 2% margin of error is due to instances where listings agents may use unrelated keywords in file names, such as labeling a home inspection report as \"seller disclosures\". As long as the document's file name is relatively clear and relates to the contents of the document, our system can process it correctly. For example: \"pest report\" for a Termite Inspection Report, \"home inspection\" for a General Inspection Report, and \"HOA financials\" for an HOA Reserve Study."
    },
    {
        question: "Can I login from different devices?",
        answer: "Yes! Your account is not limited to a single device. Usage is tacked and limited according to your membership plan."
    },
    {
        question: "Why is my analysis taking so long?",
        answer: "Out output will never take more than 1 minute to process and display. If there are any delays beyond 60 seconds then you are likely on slower wifi, and if that's the case please allow up to a couple minutes."
    },
    {
        question: "7-day money-back guarantee?",
        answer: "Yup! In the unlikely case that you do not absolutely love what we've created, please email membership@agnsy.ai within 7 calendar days of sign up for a full refund ."
    }
]