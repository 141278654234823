import React from "react";
import "./HOARentalRestrictions.css";
import { HOARentalRestrictionsAnalysis } from "../../../../../../../../model/DisclosureAnalysis/AnalysisModules/ComponentAnalysisModule/HOARentalRestrictions/HOARentalRestrictions";
import { SelectedPage } from "../../../../PDFViewer/PDFViewer";

export default function HOARentalRestrictionsAnalysisViewer({
  analysis,
  setSelectedPage,
}: {
  analysis: HOARentalRestrictionsAnalysis;
  setSelectedPage: React.Dispatch<React.SetStateAction<SelectedPage | null>>;
}): JSX.Element {
  return (
    <div>
      {analysis.restrictions.length === 0 ? (
        <div className="no-restrictions-message">
          No restrictions available for this analysis.
        </div>
      ) : (
        <div className="hoa-rental-restrictions-list">
          {analysis.restrictions.map((restriction, index) => (
            <div
              key={index}
              className="hoa-rental-restrictions-item"
              onClick={() =>
                setSelectedPage({
                  fileID: restriction.reference?.pdf?.file_id,
                  page: restriction.reference?.page,
                  region: restriction.reference?.region,
                } as SelectedPage)
              }
            >
              {restriction.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
